<script setup>
import { useServicesStore, useObjectsStore } from '@/stores/index.js'
import { ref } from 'vue'

const props = defineProps({
  template: {
    type: Object,
    required: true
  },
  fields: {
    type: Array,
    required: true
  }
})

const serviceStore = useServicesStore()
const objectsStore = useObjectsStore()

const getSourceServices = (service) => {
  return serviceStore[service]
}

const sources = ref(props.template)

const changeHandle = (value, field) => {
  sources.value[field] = value
  objectsStore.setChangedSelectFields(field, value)
}
</script>

<template>
  <div class="employees-info">
    <div v-for="field in fields" :key="field.id">
      <s-select
        :value="sources[field.title]"
        @change="changeHandle($event, field.title)"
        :options="getSourceServices(field.service)"
        :selectLabel="field.label"
        :multiple="field.multiple"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.employees-info {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
  overflow: auto;
}
</style>

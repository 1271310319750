<script setup>
import { onMounted, reactive, ref } from 'vue'

import { useObjectsStore } from '@/stores'

import ExportHeader from './components/export-header.vue'
import ExportTypes from './components/export-types.vue'
import ExportSettings from './components/export-settings.vue'
import ExportData from './components/export-data/export-data.vue'
import ExportButton from './components/export-button.vue'
import BlockLoader from '@/components/block-loader.vue'
import { exportGroups, exportTypesConfig } from './config'

const objectsStore = useObjectsStore()

defineProps({
  isVisible: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['close'])

onMounted(() => {
  const { excavationsList, reconsList } = objectsStore
  const excavsValue = excavationsList?.filter((e) => !!e.h).map((e) => ({ ...e, checked: true }))
  const reconsValue = reconsList
    ?.filter((e) => e.status === 4)
    .map((e) => ({ ...e, checked: true }))
  objectsStore.setField('excavationsToExport', excavsValue)
  objectsStore.setField('reconsToExport', reconsValue)
})

const currentType = ref(null)

const settings = reactive({
  spelled: false,
  stamp: false,
  type: 'full',
  build_in_template: null
})

const getGroupItems = (groupId) => exportTypesConfig.filter((t) => t.group === groupId)

const backToTypes = () => {
  currentType.value = null
}

const selectType = (type) => {
  currentType.value = type

  if (type.defaultTemplate) {
    settings.build_in_template = type.defaultTemplate
  }
}

const changeSettings = ([prop, value]) => {
  settings[prop] = value
}

const closeModal = () => {
  objectsStore.setField('excavationsToExport', null)
  objectsStore.setField('reconsToExport', null)
  objectsStore.setField('exporting', false)
  emits('close')
}
</script>

<template>
  <s-modal title="Экспорт данных" :show="isVisible" no-escape @close="closeModal">
    <div class="object-export">
      <export-header :current="currentType" @back="backToTypes" />
      <div class="object-export__menu">
        <ul class="object-export__groups" v-if="!currentType">
          <li class="object-export__group" v-for="group in exportGroups" :key="group.id">
            <s-text type="secondary">{{ group.title }}</s-text>
            <export-types :types="getGroupItems(group.id)" @select-type="selectType" />
          </li>
        </ul>

        <div v-else class="object-export__settings">
          <div v-if="currentType.settings" class="object-export__settings-block">
            <export-settings
              :current-type="currentType"
              :settings="settings"
              :enabled-settings="currentType.settings"
              @on-change="changeSettings"
            />
          </div>

          <div v-if="currentType.selectList" class="object-export__settings-block">
            <export-data :dataType="currentType.dataType" />
          </div>

          <div class="object-export__settings-block" v-if="currentType.recons">
            <s-alert
              type="primary"
              icon="info-circle"
              title="Будут экспортированы материалы по всем точкам наблюдения в рамках данного проекта"
            >
            </s-alert>
          </div>
        </div>
      </div>

      <block-loader title="Подготавливаем документ к скачиванию..." v-if="objectsStore.exporting" />
    </div>

    <template #footer>
      <export-button
        v-if="currentType"
        class="object-export__control"
        :current-type="currentType"
        :current-settings="settings"
        @back="backToTypes"
      />
    </template>
  </s-modal>
</template>

<style lang="scss">
.object-export {
  position: relative;
  overflow: auto;
  height: 100%;

  &__groups {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__settings {
    &-block {
      &:not(:first-child) {
        margin-top: 32px;
      }
    }
  }
}
</style>

<script setup>
import { ref, computed, onMounted, inject } from 'vue'
import { useMq } from 'vue3-mq'
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import { useRoute } from 'vue-router'

import db from '@/libs/db'
import { useRitmDate, useRequests } from '@/composables'
import { useMainStore, useExcavationStore } from '@/stores'

import { deleteObject, getLayerInfoFromData, getExcavationsChanges } from '@/utils'
import {
  clayFields,
  frozenFields,
  frozenSandFields,
  frozenLargeScaleFields,
  frozenRockFields,
  iceFields,
  largeScaleFields,
  prsFields,
  rockFields,
  sandFields,
  technoFields
} from './configs'
import { resetSoilSource } from './helpers'

import SoilDepthComp from './components/soil-depth.vue'
import SoilBasicComp from './components/soil-basic.vue'
import SoilAdditionalComp from './components/soil-additional.vue'
import SoilInclusions from './components/soil-inclusions.vue'
import SoilInterlayers from './components/soil-interlayers.vue'

const props = defineProps({
  excavation: {
    type: Object,
    required: true
  },
  soil: {
    type: Object,
    required: true
  },
  isVisible: {
    type: Boolean,
    default: false
  },
  soilsList: {
    type: Array,
    default: () => []
  }
})

const emits = defineEmits(['toggle'])

const mq = useMq()
const route = useRoute()
const rDate = useRitmDate()
const mainStore = useMainStore()
const excavationStore = useExcavationStore()
const { putRequest, postRequest, deleteRequest } = useRequests()
const $notify = inject('$notify')

const loading = ref(false)
const source = ref(cloneDeep(props.soil))
const clone = ref({})
const soilType = ref(null)
const offlineAdditional = ref(false)
const currentOpenedItemId = ref('depth')

const fieldsMap = {
  clayFields,
  frozenFields,
  frozenSandFields,
  frozenLargeScaleFields,
  frozenRockFields,
  iceFields,
  largeScaleFields,
  prsFields,
  rockFields,
  sandFields,
  technoFields,
  frozenPrsFields: frozenFields,
  technoClayFields: clayFields,
  technoLargeScaleFields: largeScaleFields,
  technoSandFields: sandFields,
  technoRockFields: rockFields
}

const prevDepth = computed(() => {
  const filteredList = props.soilsList.filter((e) => e.id !== source.value.id)
  const depth = source.value.foot_d
  const index = filteredList.findIndex((e) => e.foot_d > depth)
  const length = filteredList.length
  const prevDepth =
    index <= 0 && !filteredList.length
      ? 0
      : index < 0 && filteredList.length
        ? filteredList[length - 1]?.foot_d
        : filteredList[index - 1]?.foot_d

  return prevDepth || 0
})

const soilInterval = computed(() => {
  const depth = source.value.foot_d

  return {
    from: prevDepth.value || 0,
    to: depth || 0
  }
})

const hasChanges = computed(() => {
  return !isEqual(clone.value, source.value)
})

const basicFields = computed(() => {
  const fields = fieldsMap[`${soilType.value}Fields`]?.basic || []

  return fields
})

onMounted(async () => {
  const initInclusions = await db.inclusions.where('soil_id').equals(props.soil.id)?.toArray()
  const initInterlayers = await db.interlayers.where('soil_id').equals(props.soil.id)?.toArray()

  source.value.inclusions = initInclusions
  source.value.interlayers = initInterlayers
  clone.value = cloneDeep(source.value)
})

const cancel = () => {
  Object.entries(props.soil).forEach(([key, value]) => {
    source.value[key] = value
  })
}

const setSoilType = ({ value }) => {
  const { type, init } = value

  if (value !== soilType.value) {
    soilType.value = type

    if (init || soilType.value === type) return

    resetSoilSource(source.value)
  }
}

const saveHandler = async () => {
  loading.value = true

  try {
    if (source.value.state === 0) {
      source.value.state = 1
    }
    const id = source.value?.server_id
    const idbId = source.value?.id
    const data = cloneDeep(source.value)
    const inclusions = cloneDeep(data.inclusions)
    const interlayers = cloneDeep(data.interlayers)
    await checkAdditional('inclusions', inclusions)
    await checkAdditional('interlayers', interlayers)

    data.description = getLayerInfoFromData(source.value) || ''
    data.date_front = rDate(new Date()).format('iso')

    let idbData

    if (mainStore.isOnline && props.excavation?.server_id && !mainStore.noSyncMode) {
      delete data.inclusions
      delete data.interlayers
      delete data.excavation_id
      delete data.server_id
      delete data.url

      const soil = await putRequest(`soils/${id}/`, data)

      idbData = soil
      idbData.server_id = cloneDeep(soil.id)
      idbData.excavation_id = String(props.excavation?.server_id)
      idbData.id = idbId
      idbData.inclusions = inclusions
      idbData.interlayers = interlayers
    } else {
      idbData = data
    }
    const filter = { field: 'excavation_id', value: route.query.id }

    await db.updateObject('soils', idbData, filter)

    if ((!mainStore.isOnline || mainStore.noSyncMode) && props.excavation.server_id) {
      const updatedItems = await db.updated.where('table').equals('soils').toArray()
      const inUpdated = !!updatedItems.find((e) => e.item_id === idbData.server_id)
      const createdItems = await db.created.where('table').equals('soils').toArray()
      const inCreated = !!createdItems.find((e) => e.item_id === idbData.id)

      if (offlineAdditional.value || (!inUpdated && !inCreated)) {
        await db.updated.put({
          table: 'soils',
          item_id: idbData.server_id,
          date: new Date()
        })
        getExcavationsChanges()
      }
    }

    const title = 'Редактирование'
    const message = `Слой успешно изменен`

    $notify({
      title,
      message,
      type: 'success'
    })
    emits('toggle')
  } catch (e) {
    const title = 'Редактирование'
    const message = `Ошибка при редактировании слоя ${e}`

    $notify({
      title,
      message,
      type: 'error'
    })
  } finally {
    loading.value = false
  }
}

const checkAdditional = async (type, value) => {
  try {
    const soilIdbId = source.value.id
    const initList = await db[type].where('soil_id').equals(soilIdbId)?.toArray()
    const createdItems = await db.created.where('table').equals(type).toArray()
    const updatedItems = await db.updated.where('table').equals(type).toArray()
    const soilId = source.value?.server_id
    const clone = initList
    const created = value?.filter(
      (e) => !e.url && !clone.find((v) => v.offline_id === e.offline_id)
    )
    const deleted = clone?.filter(
      (c) =>
        !value.find((v) => {
          if (c.offline_id) {
            return v.offline_id === c.offline_id
          } else {
            return v.server_id === c.server_id
          }
        })
    )
    const updated = value?.filter((v) => {
      const initial = clone?.find((c) => c.id === v.id && v.offline_id === c.offline_id)
      if (!initial) return false
      return Object.keys(v)?.some((k) => initial[k] !== v[k])
    })

    const wasCreated = {}

    if (mainStore.isOnline && props.excavation?.server_id && !mainStore.noSyncMode) {
      if (created?.length) {
        await Promise.all(
          created.map(async (e) => {
            e.date_front = new Date()

            const serverData = await postRequest(`soils/${soilId}/${type}/`, e)

            wasCreated[e.id] = serverData.id
          })
        )
      }
      if (deleted?.length) {
        await Promise.all(
          deleted.map(async (e) => {
            await deleteRequest(`${type}/${e.server_id}/`)
          })
        )
      }
      if (updated?.length) {
        await Promise.all(
          updated.map(async (e) => {
            e.date_front = new Date()
            await putRequest(`${type}/${e.server_id}/`, e)
          })
        )
      }
    }
    if (created?.length) {
      await Promise.all(
        created.map(async (e) => {
          const server_id = wasCreated[e.id]
          e.soil_server_id = source.value.server_id || null
          e.soil_id = source.value.id
          e.server_id = server_id
          if (e.id) delete e.id

          const responseId = await db.addObject(type, cloneDeep(e), false, false)

          if (!mainStore.isOnline || mainStore.noSyncMode) {
            await db.created.add({
              table: type,
              date: new Date(),
              item_id: responseId
            })
            offlineAdditional.value = true
          }
        })
      )
    }
    if (updated?.length) {
      await Promise.all(
        updated.map(async (e) => {
          await db.updateObject(type, e, false, false)

          if (!mainStore.isOnline || mainStore.noSyncMode) {
            await db.updated.put({
              table: type,
              item_id: e.id,
              date: new Date()
            })
            offlineAdditional.value = true
          }
        })
      )
    }
    if (deleted?.length) {
      await Promise.all(
        deleted.map(async (e) => {
          await db.deleteObject(type, e.id)

          if (!mainStore.isOnline || mainStore.noSyncMode) {
            if (e.server_id) {
              await db.deleted.add({
                table: type,
                item_id: e.server_id,
                date: new Date(),
                delete_url: `${type}/${e.id}/`,
                object_id: props.excavation.object_id,
                soil_id: props.soil.server_id,
                title: props.excavation.title,
                excavation_id: props.excavation.server_id || props.excavation.id
              })
            } else {
              const inCreated = createdItems?.find((c) => c.item_id === e.id)
              const inUpdated = updatedItems?.find((c) => c.item_id === e.id)

              if (inCreated) {
                await db.created.delete(inCreated.id)
              }
              if (inUpdated) {
                await db.updated.delete(inUpdated.id)
              }
            }
            offlineAdditional.value = true
          }
        })
      )
    }
  } catch (e) {
    throw new Error(e)
  }
}

const handleDelete = async () => {
  await deleteObject(source.value, 'soils', updateSoils)
}

const updateSoils = () => {
  excavationStore.setField('updateList', true)
  emits('toggle')
}

const changeActiveCollapse = (id) => {
  currentOpenedItemId.value = id
}
</script>

<template>
  <s-modal
    title="Редактирование слоя"
    :show="isVisible"
    :fullscreen="mq.current !== 'lg'"
    :min-height="460"
    :confirm-condition="hasChanges"
    confirm-on-cancel
    @close="emits('toggle')"
  >
    <div v-loading="loading" class="soil-editor">
      <s-collapse v-model="currentOpenedItemId" accordion class="background">
        <soil-depth-comp
          :source="source"
          :soil-interval="soilInterval"
          :active-id="currentOpenedItemId"
          @change="changeActiveCollapse"
        />
        <soil-basic-comp
          :source="source"
          :soil-interval="soilInterval"
          :fields="basicFields"
          :active-id="currentOpenedItemId"
          @change="changeActiveCollapse"
          @set-soil-type="setSoilType"
        />
        <soil-additional-comp
          :source="source"
          :soil-interval="soilInterval"
          :active-id="currentOpenedItemId"
          @change="changeActiveCollapse"
          @set-soil-type="setSoilType"
        />
        <soil-inclusions
          :source="source"
          :soil-interval="soilInterval"
          :active-id="currentOpenedItemId"
          @change="changeActiveCollapse"
        />
        <soil-interlayers
          :source="source"
          :soil-interval="soilInterval"
          :active-id="currentOpenedItemId"
          @change="changeActiveCollapse"
        />
      </s-collapse>
    </div>
    <template #footer>
      <div :class="['soil-editor-footer', { hasChanges }]">
        <s-button icon="trash-can" simple icon-color="var(--error)" @click="handleDelete" />
        <s-button v-if="hasChanges" simple @click="cancel"> Отменить</s-button>
        <s-button type="success" :disabled="!hasChanges" @click="saveHandler"> Сохранить</s-button>
      </div>
    </template>
  </s-modal>
</template>

<style lang="scss">
.soil-editor {
  display: grid;
  align-content: start;
  grid-gap: 2rem;
  height: 100%;
  overflow: auto;

  &-footer {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1rem;

    &.hasChanges {
      grid-template-columns: auto auto 1fr;
    }
  }
}
</style>

<script setup>
import { computed } from 'vue'
import SAttachmentsImages from '@/components/s-attachments/s-attachments-images.vue'

const props = defineProps({
  no: {
    type: Number,
    required: true
  },
  corebox: {
    type: Object,
    required: true
  }
})

const preview = computed(() => {
  return [
    {
      id: 'preview',
      blob: props.corebox?.image || null,
      image_url: props.corebox?.image_url || null,
      thumbnail_url: props.corebox?.thumbnail_url || null
    }
  ]
})

const getDepth = (corebox) => {
  const { foot_d0, foot_d1 } = corebox

  return `${foot_d0?.toFixed(1)} - ${foot_d1?.toFixed(1)}`
}
</script>

<template>
  <div class="coreboxes-list__item">
    <s-text>{{ no }}</s-text>
    <s-text type="info">
      {{ getDepth(corebox) }}
    </s-text>
    <s-attachments-images :items="preview" />
    <s-text v-if="corebox.comments" type="secondary">
      {{ corebox.comments }}
    </s-text>
  </div>
</template>

<style lang="scss">
.coreboxes-list__item {
  display: grid;
  grid-template-columns: auto 60px auto auto;
  justify-content: start;
  align-items: center;
  padding: 1rem;
  grid-gap: 2rem;
  border-radius: var(--border-radius);
  border: 1px solid var(--main-bg);
  background-color: var(--bg);
  cursor: pointer;
}
</style>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import VirtualList from 'vue3-virtual-scroll-list'

import { listTips } from '../config'
import ListTipComp from '@/components/list-tip.vue'
import DataItem from '@/components/s-data-item/s-data-item.vue'

const props = defineProps({
  excavations: {
    type: Array,
    default: () => []
  },
  hasObjName: {
    type: Boolean
  },
  filtered: {
    type: Boolean,
    default: false
  }
})

const route = useRoute()

const listTip = computed(() => {
  if (!props.filtered) {
    switch (route.query.type) {
      case 'recon':
        return listTips.recons
      case 'excavations':
        return listTips.default
      case 'all':
        return listTips.all
      default:
        return listTips.all
    }
  } else {
    return listTips.filtered
  }
})
</script>

<template>
  <virtual-list
    v-if="excavations.length"
    class="excavations-virtual-list"
    :data-key="'id'"
    :data-sources="excavations"
    :data-component="DataItem"
    :estimate-size="66"
    :extra-props="{ hasObjName }"
  />
  <list-tip-comp v-else :tip="listTip" />
</template>

<style lang="scss">
.excavations-virtual-list {
  display: grid;
  align-content: start;
  max-height: 100%;
  overflow: auto;
  padding-right: 0.5rem;

  > * {
    > * {
      position: relative;
      margin-top: 1rem;
      box-sizing: border-box;
      border-radius: 4px;
      cursor: pointer;

      &::after {
        content: '';
        display: block;
        position: absolute;
        height: 1px;
        width: 100%;
        background-color: var(--bg);
        left: 0;
        bottom: -0.5rem;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}

.light-theme {
  .excavations-virtual-list {
    > * {
      > * {
        &::after {
          background-color: var(--main-bg);
        }
      }
    }
  }
}
</style>

<script setup>
import { reactive, ref } from 'vue'

const props = defineProps({
  filters: {
    type: Object,
    required: true
  }
})

const emits = defineEmits(['after-select', 'change-field'])

const filters = reactive(props.filters.filters || [])
const sort = ref(props.filters.sort || null)

const toggleSort = () => {
  if (sort.value.order === 'asc') {
    sort.value.order = 'desc'
  } else {
    sort.value.order = 'asc'
  }

  emits('change-field', 'sortorder', sort.value.order)
}

const selectHandler = (selectValue) => {
  sort.value.value = selectValue
  emits('change-field', 'sortby', sort.value.value)
  emits('after-select', selectValue)
}

const checkboxHandle = (item, id, value) => {
  emits('change-field', item, id, value)
}
</script>

<template>
  <div class="s-search-block-filters">
    <s-title type="small">
      {{ props.filters.title || 'Фильтры и сортировка' }}
    </s-title>
    <template v-for="filter in filters" :key="filter.id">
      <div class="s-search-block-filters-block">
        <div v-if="filter.filterValues.length" class="s-search-block-filters-block">
          <s-text>
            {{ filter.filterTitle }}
          </s-text>
          <div
            v-for="item in filter.filterValues"
            :key="item.id"
            class="s-search-block-filters__filter-item"
          >
            <s-checkbox
              @change="checkboxHandle(filter.id, item.id, item.value)"
              v-model="item.value"
              start
              :id="item.id"
              :label="item.title"
            />
          </div>
        </div>
      </div>
    </template>
    <div v-if="sort" class="s-search-block-filters-block">
      <s-text>Сортировать по</s-text>
      <div class="s-search-block-filters-block-sort">
        <s-select
          :value="sort.value"
          :placeholder="sort.placeholder || 'Не выбрано'"
          :options="sort.list"
          @change="selectHandler"
        />
        <div class="s-search-block-filters-block-sort__order" @click="toggleSort">
          <s-icon size="lg" :name="sort.order === 'asc' ? 'arrow-down-1-9' : 'arrow-down-9-1'" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.s-search-block-filters {
  display: grid;
  grid-gap: 1.5rem;

  &-block {
    display: grid;
    grid-gap: 0.5rem;

    &-sort {
      display: grid;
      grid-auto-flow: column;
      max-width: 220px;
      grid-gap: 1rem;
      cursor: pointer;

      &__order {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--border-radius);
        background-color: var(--secondary-bg);
      }
    }
  }
}
</style>

import { defineStore } from 'pinia'

export const useObjectsStore = defineStore('objects', {
  state: () => {
    return {
      activeObject: null,
      objectEditorHasChanges: false,
      isShowExcavationEditorModal: false,
      objectsList: null,
      excavationsList: null,
      isShowReconEditorModal: false,
      reconsList: null,
      isCollapsed: false,
      isCollapseAnimationEnd: false,
      exporting: false,
      excavationsToExport: [],
      reconsToExport: [],
      samplesList: null,
      changedSelectFields: {},
      lastQueryDataPageValue: null,
      actualGeologistList: null
    }
  },
  actions: {
    setField(field, value) {
      if (field.includes('.')) {
        const subField = field.split('.')

        this[subField[0]][subField[1]] = value
      } else {
        this[field] = value
      }
    },
    setChangedSelectFields(field, value) {
      this.changedSelectFields[field] = value
    }
  },
  getters: {
    getActualGeologistList: (state) => {
      let excavsGeologist = [],
        reconsGeologist = []

      if (state.excavationsList) {
        excavsGeologist = state.excavationsList.map((excav) => excav.geologist)
      }
      if (state.reconsList) {
        reconsGeologist = state.reconsList.map((recon) => recon.geologist)
      }

      return Array.from(new Set([...excavsGeologist, ...reconsGeologist]))
    }
  }
})

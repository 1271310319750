export const exportGroups = [
  { id: 'excavations', title: 'Скважины' },
  { id: 'recons', title: 'Точки наблюдения' }
]

export const exportTypesConfig = [
  {
    id: 'json',
    label: 'Выгрузка в EngGeo',
    description: 'JSON-файл',
    icon: 'database',
    settings: null,
    api: 'json_exports',
    extension: 'json',
    group: 'excavations',
    selectList: true,
    dataType: 'excavations',
    selectParam: 'excavations_to_report'
  },
  {
    id: 'column',
    label: 'Колонки скважин',
    description: 'Документ Word',
    icon: 'file-word',
    settings: ['type'],
    api: 'column_exports',
    extension: 'docx',
    group: 'excavations',
    selectList: true,
    dataType: 'excavations',
    selectParam: 'excavations_to_report'
  },
  {
    id: 'bore_journal',
    label: 'Цифровой буровой журнал',
    description: 'Документ Word',
    icon: 'file-word',
    settings: ['build_in_template'],
    templatesList: [
      {
        id: 'journal_GOST',
        api: 'excavations_export',
        title: 'Сокращенный (без абриса и адреса, без статистики)'
      },
      {
        id: 'digital_journal_new',
        api: 'digital_report',
        title: 'Упрощенный (с абрисом и адресом, без статистики)'
      },
      {
        id: 'digital_journal_new_full',
        api: 'digital_report',
        title: 'Полный (с абрисом и адресом, со статистикой)'
      }
    ],
    defaultTemplate: 'journal_GOST',
    extension: 'docx',
    group: 'excavations',
    selectList: true,
    dataType: 'excavations',
    selectParam: 'excavations_to_report'
  },
  {
    id: 'excavations_photos_core_export',
    label: 'Все фото',
    description: 'Документ Word',
    icon: 'file-word',
    settings: ['build_in_template', 'stamp'],
    templatesList: [
      {
        id: 'excavations_photos_core_export',
        api: 'excavations_photos_core_export',
        title: 'Стандартный'
      }
    ],
    defaultTemplate: 'excavations_photos_core_export',
    extension: 'docx',
    group: 'excavations',
    selectList: true,
    dataType: 'excavations',
    selectParam: 'excavations_to_report'
  },
  // {
  //   id: 'process',
  //   label: 'Общие фото скважин',
  //   description: 'Документ Word',
  //   icon: 'file-word',
  //   settings: null,
  //   excavations: true,
  //   api: 'process_exports',
  //   extension: 'docx',
  //   group: 'excavations'
  // },
  {
    id: 'process_v2',
    label: 'Общие фото скважин',
    description: 'Документ Word',
    icon: 'file-word',
    settings: ['stamp'],
    api: 'process_exports/v2',
    extension: 'docx',
    group: 'excavations',
    selectList: true,
    dataType: 'excavations',
    selectParam: 'excavations_to_report'
  },
  {
    id: 'process_files',
    label: 'Общие фото скважин',
    description: 'Архив фото',
    icon: 'file-image',
    settings: null,
    api: 'process_files',
    extension: 'zip',
    group: 'excavations',
    selectList: true,
    dataType: 'excavations',
    selectParam: 'excavations_to_report'
  },
  // {
  //   id: 'sample',
  //   label: 'Фотографии проб',
  //   description: 'Документ Word',
  //   icon: 'file-word',
  //   settings: null,
  //   excavations: true,
  //   api: 'sample_exports',
  //   extension: 'docx',
  //   group: 'excavations'
  // },
  {
    id: 'sample_v2',
    label: 'Фотографии проб',
    description: 'Документ Word',
    icon: 'file-word',
    settings: ['stamp'],
    api: 'sample_exports/v2',
    extension: 'docx',
    group: 'excavations',
    selectList: true,
    dataType: 'excavations',
    selectParam: 'excavations_to_report'
  },
  {
    id: 'sample_files',
    label: 'Фотографии проб',
    description: 'Архив фото',
    icon: 'file-image',
    settings: null,
    api: 'sample_files',
    extension: 'zip',
    group: 'excavations',
    selectList: true,
    dataType: 'excavations',
    selectParam: 'excavations_to_report'
  },
  {
    id: 'sample_sheet',
    label: 'Ведомость проб',
    description: 'Документ Word',
    icon: 'file-word',
    settings: null,
    api: 'sample_sheet_exports',
    extension: 'docx',
    group: 'excavations',
    selectList: true,
    dataType: 'excavations',
    selectParam: 'excavations_to_report'
  },
  {
    id: 'excel_sample_sheet_exports',
    label: 'Ведомость проб',
    description: 'Документ Excel',
    icon: 'file-excel',
    settings: ['type'],
    api: 'excel_sample_sheet_exports',
    extension: 'xlsx',
    group: 'excavations',
    selectList: true,
    dataType: 'excavations',
    selectParam: 'excavations_to_report'
  },
  // {
  //   id: 'core',
  //   label: 'Фотографии керна',
  //   description: 'Документ Word',
  //   icon: 'file-word',
  //   settings: null,
  //   excavations: true,
  //   api: 'core_exports',
  //   extension: 'docx',
  //   group: 'excavations'
  // },
  {
    id: 'core_v2',
    label: 'Фотографии керна',
    description: 'Документ Word',
    icon: 'file-word',
    settings: ['stamp'],
    api: 'core_exports/v2',
    extension: 'docx',
    group: 'excavations',
    selectList: true,
    dataType: 'excavations',
    selectParam: 'excavations_to_report'
  },
  {
    id: 'core_files',
    label: 'Фотографии керна',
    description: 'Архив фото',
    icon: 'file-image',
    settings: null,
    api: 'core_files',
    extension: 'zip',
    group: 'excavations',
    selectList: true,
    dataType: 'excavations',
    selectParam: 'excavations_to_report'
  },
  {
    id: 'reconnaissance_photos_exports',
    label: 'Точки наблюдения',
    description: 'Документ Word',
    icon: 'file-word',
    settings: ['stamp'],
    api: 'reconnaissance_photos_exports/v2',
    extension: 'docx',
    group: 'recons',
    selectList: true,
    dataType: 'recons',
    selectParam: 'ids_to_report'
  },
  {
    id: 'reconnaissance_journals_exports',
    label: 'Фото журналов ТН',
    description: 'Документ Word',
    icon: 'file-word',
    settings: ['stamp'],
    api: 'reconnaissance_journals_exports/v2',
    extension: 'docx',
    group: 'recons',
    selectList: true,
    dataType: 'recons',
    selectParam: 'ids_to_report'
  },
  {
    id: 'recon_images_files',
    label: 'Общие фото ТН',
    description: 'Архив фото',
    icon: 'file-image',
    settings: null,
    api: 'recon_images_files',
    extension: 'zip',
    group: 'recons',
    selectList: true,
    dataType: 'recons',
    selectParam: 'ids_to_report'
  },
  {
    id: 'recon_journal_files',
    label: 'Фото журналов ТН',
    description: 'Архив фото',
    icon: 'file-image',
    settings: null,
    api: 'recon_journal_files',
    extension: 'zip',
    group: 'recons',
    selectList: true,
    dataType: 'recons',
    selectParam: 'ids_to_report'
  }
]

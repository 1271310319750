<script setup>
import { ref, inject, computed, onMounted } from 'vue'
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import { useMq } from 'vue3-mq'
import db from '@/libs/db'
import { useRouter } from 'vue-router'
import { createRecon, editRecon } from './helpers'
import { deleteHandler, deleteImages, getUuid, saveImages } from '@/utils'
import { useServicesStore, useMainStore, useReconStore } from '@/stores'
import { useRequests } from '@/composables'
import {
  reconTemplate,
  basicItem,
  fieldsList,
  locationFields,
  locationFactFields
} from '../../configs'
import { galleryFields } from '../../configs/data-card.js'

import SLocationEditor from '@/components/s-location-editor/s-location-editor.vue'
import CollapseItem from '@/components/collapse-item.vue'
import ReconEditorImages from './recon-editor-images.vue'
import BlockLoader from '@/components/block-loader.vue'

const props = defineProps({
  objectId: {
    type: [String, Number],
    default: null
  },
  title: {
    type: String,
    default: 'Создание точки наблюдения'
  },
  recon: {
    type: Object,
    default: () => null
  },
  isVisible: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['update-handler', 'toggle'])

const $notify = inject('$notify')
const { getRequest } = useRequests()
const router = useRouter()
const mq = useMq()
const servicesStore = useServicesStore()
const mainStore = useMainStore()
const reconStore = useReconStore()

const loading = ref(false)
const ready = ref(false)
const triggered = ref(false)
const reconDataTemplate = ref(cloneDeep(reconTemplate))
const initSource = ref(null)
const currentOpenedItemId = ref(1)

const commonImages = ref([])
const cloneCommonImages = ref([])
const commonImagesFiles = ref([])

const journalImages = ref([])
const cloneJournalImages = ref([])
const journalImagesFiles = ref([])

const hasChanges = computed(() => {
  return !isEqual(initSource.value, reconDataTemplate.value)
})

const initObjectId = computed(() => {
  return props.objectId || props.recon?.object_id
})

const initBasicItem = computed(() => {
  const object = Object.assign({}, basicItem)

  return object
})

const services = computed(() => {
  const { object_bore_machines, object_users, object_bore_masters, processes, recon, excav } =
    servicesStore
  const { statuses } = recon
  const { types } = processes
  const { geomorphies } = excav

  return {
    statuses,
    geomorphies,
    object_bore_machines,
    object_bore_masters,
    object_users,
    types
  }
})

const disabled = computed(() => {
  const basic = basicItem?.fields
    ?.filter((e) => e.required)
    ?.some((e) => reconDataTemplate.value[e.title] !== 0 && !reconDataTemplate.value[e.title])

  const locationFieldsLocal = !props.excavation ? locationFields : locationFactFields
  const location = locationFieldsLocal
    ?.filter((e) => {
      if (reconDataTemplate.value.id && reconDataTemplate.value.status === 1) {
        return false
      } else {
        return e.required
      }
    })
    ?.some((e) => !reconDataTemplate.value[e.title])

  const info = []
  fieldsList.forEach((e) => info.push(...e.fields))
  const infoRequired = info
    ?.filter((e) => e.required && !reconDataTemplate.value[e.title])
    .some((e) => e.required)

  return basic || location || infoRequired
})

const requiredFields = computed(() => {
  if (!triggered.value) return []

  const basic = basicItem?.fields
    ?.filter(
      (e) =>
        e.required && reconDataTemplate.value[e.title] !== 0 && !reconDataTemplate.value[e.title]
    )
    .map((e) => e.title)
  const locationFieldsLocal = !props.excavation ? locationFields : locationFactFields
  const location = locationFieldsLocal
    ?.filter((e) => {
      if (reconDataTemplate.value.id && reconDataTemplate.value.status === 1) {
        return false
      } else {
        return e.required && !reconDataTemplate.value[e.title]
      }
    })
    .map((e) => e.title)
  const info = []
  fieldsList.forEach((e) => info.push(...e.fields))
  const infoRequired = info
    ?.filter((e) => e.required && !reconDataTemplate.value[e.title])
    .map((e) => e.title)

  return [...basic, ...location, ...infoRequired]
})

const init = async () => {
  if (props.recon) {
    try {
      reconDataTemplate.value = await cloneDeep(props.recon)
      await loadCommonImages()
      await loadJournalImages()
    } catch (e) {
      console.log(e)
      $notify({
        message: `Произошла ошибка при загрузке фотографий. ${e}`,
        type: 'error'
      })
    }
  }

  ready.value = true
  initSource.value = cloneDeep(reconDataTemplate.value)
}

const loadCommonImages = async () => {
  loading.value = true

  const { server_id, id } = props.recon

  try {
    const imagesLocal = []
    if (mainStore.isOnline && !mainStore.noSyncMode && server_id) {
      const response = await getRequest(`reconnaissance/${server_id}/images/`)
      response.forEach((e) => {
        imagesLocal.push(e)
      })
    }
    const idb = await db.images
      .where({
        table: 'recons',
        item_id: id,
        image_type: 'images'
      })
      .toArray()

    idb.forEach((e) => {
      imagesLocal.push({
        ...e,
        idb: true
      })
    })

    commonImages.value = imagesLocal || []
    cloneCommonImages.value = cloneDeep(commonImages.value)
  } catch (e) {
    throw new Error(e)
  } finally {
    loading.value = false
  }
}

const loadJournalImages = async () => {
  loading.value = true

  const { server_id, id } = props.recon

  try {
    const imagesLocal = []
    if (mainStore.isOnline && !mainStore.noSyncMode && server_id) {
      const response = await getRequest(`reconnaissance/${server_id}/journal/`)
      response.forEach((e) => {
        imagesLocal.push(e)
      })
    }
    const idb = await db.images
      .where({
        table: 'recons',
        item_id: id,
        image_type: 'journal'
      })
      .toArray()

    idb.forEach((e) => {
      imagesLocal.push({
        ...e,
        idb: true
      })
    })

    journalImages.value = imagesLocal || []
    cloneJournalImages.value = cloneDeep(journalImages.value)
  } catch (e) {
    throw new Error(e)
  } finally {
    loading.value = false
  }
}

const isWarning = (fields) => {
  if (!triggered.value) return false
  if (!fields?.length) return false

  const required = fields?.filter((e) => e.required)

  if (!required?.length) return false

  return required.some(
    (e) => reconDataTemplate.value[e.title] !== 0 && !reconDataTemplate.value[e.title]
  )
}

const toggleModal = (action) => {
  if (action) {
    emits('update-handler')
  }
  emits('toggle')
  triggered.value = false
}

const createHandler = async () => {
  if (disabled.value) {
    triggered.value = true
    return
  }

  loading.value = true

  const filter = { field: 'object_id', value: initObjectId.value }

  try {
    if (!props.recon) {
      await createRecon(
        initObjectId.value,
        reconDataTemplate.value,
        loading.value,
        toggleModal,
        filter
      )
    } else {
      await editRecon(reconDataTemplate.value, loading.value, toggleModal, filter)
    }
  } finally {
    loading.value = false
    if (cloneCommonImages.value?.length) {
      updateImages(cloneCommonImages.value, commonImages.value, 'updateReconCommonImages', 'images')
    }

    if (commonImagesFiles.value?.length) {
      saveImagesLocal(commonImagesFiles.value, 'updateReconCommonImages', 'images')
    }

    if (cloneJournalImages.value?.length) {
      updateImages(
        cloneJournalImages.value,
        journalImages.value,
        'updateReconJournalImages',
        'journal'
      )
    }

    if (journalImagesFiles.value?.length) {
      saveImagesLocal(journalImagesFiles.value, 'updateReconJournalImages', 'journal')
    }
  }
}

const saveImagesLocal = async (files, storeUpdateField, type) => {
  const { server_id, id } = props.recon

  const data = {
    server_id,
    table: 'recons',
    item_id: id,
    files,
    image_type: type,
    source_id: server_id || `idb_${id}`,
    uuid: getUuid()
  }

  await saveImages(data)
  reconStore.setField(storeUpdateField, true)
}

const updateImages = async (clone, images, storeUpdateField, type) => {
  const deleted = clone?.filter((c) => !images.find((im) => im.id === c.id))
  const { server_id, id } = props.recon

  const data = {
    files: deleted,
    source: props.recon,
    server_id,
    table: 'recons',
    item_id: id,
    image_type: type,
    source_id: server_id || `idb_${id}`
  }

  await deleteImages(data)
  reconStore.setField(storeUpdateField, true)
}

const deleteHandlerLocal = async () => {
  loading.value = true
  try {
    await deleteHandler(props.recon, 'recons', goToObject, 'recons')
  } catch (e) {
    console.log(e)
  } finally {
    loading.value = false
    toggleModal()
  }
}

const goToObject = () => {
  router.back()
}

const changeActiveCollapse = (id) => {
  currentOpenedItemId.value = id
}

const galleryDataMap = computed(() => {
  return {
    images: commonImages.value,
    files: commonImagesFiles.value,
    journalImages: journalImages.value,
    journalFiles: journalImagesFiles.value
  }
})

onMounted(() => {
  init()
})
</script>

<template>
  <s-modal
    :title="title"
    :show="true"
    :fullscreen="mq.current !== 'lg'"
    :confirm-on-cancel="true"
    :confirm-condition="hasChanges"
    @close="toggleModal"
  >
    <div v-if="!loading" class="recon-editor">
      <s-collapse v-if="ready" class="background">
        <collapse-item
          :item="initBasicItem"
          :title="initBasicItem.title"
          :source="reconDataTemplate"
          :services="services"
          :warning="isWarning(initBasicItem.fields)"
          :required="requiredFields"
          :active-id="currentOpenedItemId"
          @change="changeActiveCollapse"
        />
        <s-location-editor
          :source="reconDataTemplate"
          :warning="isWarning(!recon ? locationFields : locationFactFields)"
          :required="requiredFields"
          :locationFields="locationFields"
          :locationFactFields="locationFactFields"
          :new-item="!recon"
          :item="recon"
          :active-id="currentOpenedItemId"
          @change="changeActiveCollapse"
        />
        <template v-if="reconDataTemplate?.id">
          <collapse-item
            v-for="listItem in fieldsList"
            :key="listItem.id"
            :item="listItem"
            :source="reconDataTemplate"
            :services="services"
            :warning="isWarning(listItem.fields)"
            :required="requiredFields"
            :active-id="currentOpenedItemId"
            @change="changeActiveCollapse"
          />
          <recon-editor-images
            v-for="field in galleryFields"
            :key="field.id"
            :loading="loading"
            :files="galleryDataMap[field.files]"
            :images="galleryDataMap[field.images]"
            :active-id="currentOpenedItemId"
            @change="changeActiveCollapse"
            :id="field.id"
            :title="field.title"
          />
        </template>
      </s-collapse>
    </div>
    <Block-loader title="Загрузка точки наблюдения..." v-else />
    <template #footer>
      <div class="excavation-editor-footer">
        <s-button
          icon="trash-can"
          simple
          icon-color="var(--error)"
          v-if="recon"
          @click="deleteHandlerLocal"
        />
        <s-button
          type="success"
          stretch
          :loading="loading"
          :disabled="loading"
          @click="createHandler"
        >
          Сохранить
        </s-button>
      </div>
    </template>
  </s-modal>
</template>

<style lang="scss">
.recon-editor {
  display: grid;
  align-content: start;
  height: 100%;
  overflow: auto;

  &-footer {
    display: flex;
    gap: 1rem;
  }
}
</style>

<script setup>
import { computed, ref } from 'vue'
import SoilTypeComp from './soil-type.vue'
import SoilColorComp from './soil-color-comp.vue'
import SoilWaterComp from './soil-water-comp.vue'
import SoilGroupComp from './soil-group.vue'
import SoilSelectComp from './soil-select.vue'
import SoilStatePfrostComp from './soil-state-pfrost.vue'
import SoilIceContentComp from './soil-ice-content.vue'

const props = defineProps({
  source: {
    type: Object,
    required: true
  },
  soilInterval: {
    type: Object,
    required: true
  },
  fields: {
    type: Array,
    required: true
  },
  warning: {
    type: Boolean,
    default: false
  },
  activeId: {
    type: [Number, String],
    default: null
  }
})
const emits = defineEmits(['set-soil-type', 'change'])

const initSource = ref(props.source)

const getCount = () => {
  return `${validLength.value + 2} / ${(allFields.value?.length || 0) + 2}`
}

const validLength = computed(() => {
  return allFields.value?.filter((f) => initSource.value[f.id])?.length || 0
})

const allFields = computed(() => {
  const groups = props.fields?.filter((f) => f.type === 'group')
  const fields = props.fields?.filter((f) => f.type !== 'group')

  groups.forEach((g) => {
    g.groups.forEach((e) => {
      fields.push(e)
    })
  })

  return fields
})

const hasError = computed(() => {
  return false
})

const iteratedFields = computed(() => {
  return props.fields.filter((f) => {
    return f.type !== 'soil-water-comp'
  })
})

const hasWaterComp = computed(() => {
  return !!allFields.value?.find((f) => f.id === 'water_saturation')
})

const hasAll = computed(() => {
  return validLength.value === allFields.value?.length
})

const changeActiveCollapse = (id) => {
  emits('change', id)
}

const soilMapComp = {
  group: SoilGroupComp,
  select: SoilSelectComp,
  'state-pfrost': SoilStatePfrostComp,
  'ice-content': SoilIceContentComp
}

const getComponent = (name) => {
  if (soilMapComp[name]) {
    return soilMapComp[name]
  }
}
</script>

<template>
  <s-collapse-item
    @change="changeActiveCollapse"
    :active="activeId"
    id="soil-basic"
    title="Основные характеристики"
  >
    <template v-slot:header-stats>
      <s-icon
        v-if="hasError"
        name="circle-exclamation"
        size="sm"
        color="var(--error)"
        class="header-stats__icon"
      />
      <s-text class="soil-basic__count" :type="hasError ? 'error' : hasAll ? 'success' : 'default'">
        {{ getCount() }}
      </s-text>
    </template>
    <div class="soil-basic">
      <soil-type-comp
        :source="initSource"
        @set-soil-type="emits('set-soil-type', { value: $event })"
      />
      <soil-color-comp :source="initSource" />
      <soil-water-comp v-if="hasWaterComp" :source="initSource" />
      <component
        :is="getComponent(field.type)"
        v-for="field in iteratedFields"
        :key="field.id"
        :source="initSource"
        :field="field"
      />
    </div>
  </s-collapse-item>
</template>

<style lang="scss">
.soil-basic {
  display: grid;
  grid-gap: 1.5rem;

  &__text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__count {
    white-space: nowrap;
  }
}
</style>

import cloneDeep from 'lodash.clonedeep'

import { useSyncStore, useMainStore } from '@/stores'
import { useRequests } from '@/composables'
import db from '@/libs/db'
import { saveImageOnServer } from '@/utils'

export const getDataChanges = async () => {
  const syncStore = useSyncStore()
  const mainStore = useMainStore()
  syncStore.setField('loading', true)

  try {
    await getReconChanges()
    await getChangedItems('images')

    syncStore.setField('initialDataList', cloneDeep(syncStore.dataChangesList))

    if (syncStore.dataChangesList?.length) {
      mainStore.setNoSyncMode(true)
    }
  } catch (e) {
    throw new Error(e)
  } finally {
    syncStore.setField('loading', false)
  }
}

const getReconChanges = async () => {
  const syncStore = useSyncStore()

  const items = []

  try {
    const deletedData = await db.deleted.where('table').equals('recons').toArray()
    await Promise.all(
      deletedData.map(async (e) => {
        await parseRecons('deleted', e, items)
      })
    )
    const updatedData = await db.updated.where('table').equals('recons').toArray()
    const filteredUpdated = updatedData.filter(
      (e) => !deletedData.find((d) => d.item_id === e.item_id)
    )
    await Promise.all(
      filteredUpdated.map(async (e) => {
        await parseRecons('updated', e, items)
      })
    )
    const deletedUpdated = updatedData.filter((e) =>
      deletedData.find((d) => d.item_id === e.item_id)
    )
    await Promise.all(
      deletedUpdated.map(async (e) => {
        await db.updated.delete(e.id)
      })
    )
    const createdData = await db.created.where('table').equals('recons').toArray()
    await Promise.all(
      createdData.map(async (e) => {
        await parseRecons('created', e, items, 'id')
      })
    )

    syncStore.setField('dataChangesList', items)
  } catch (e) {
    throw new Error(e)
  }
}

const parseRecons = async (action, item, items, keyField = 'server_id') => {
  const data = await db.recons.where(keyField).equals(item.item_id).first()
  let object_id, title

  if (action === 'deleted') {
    object_id = item?.object_id
    title = item?.title
  } else {
    object_id = data?.object_id
    title = data?.title
  }

  const objectData = object_id ? await db.objects.where('server_id').equals(object_id).first() : '-'
  const parseData = {
    id: `recons:${action}:${item.item_id}`,
    object: objectData?.title_short || '-',
    item_id: item.item_id,
    type: 201,
    idbId: item.id,
    updates: [],
    isUpdated: true,
    data: data,
    action,
    title,
    object_id
  }
  items.push(parseData)
}

const getChangedItems = async (table) => {
  const items = []

  try {
    const deletedData = await db.deleted.where('table').equals(table).toArray()
    await Promise.all(
      deletedData.map(async (e) => {
        await parseItems(table, 'deleted', e, items)
      })
    )
    const updatedData = await db.updated.where('table').equals(table).toArray()
    const filteredUpdated = updatedData.filter(
      (e) => !deletedData.find((d) => d.item_id === e.item_id)
    )
    await Promise.all(
      filteredUpdated.map(async (e) => {
        await parseItems(table, 'updated', e, items)
      })
    )
    const deletedUpdated = updatedData.filter((e) =>
      deletedData.find((d) => d.item_id === e.item_id)
    )
    await Promise.all(
      deletedUpdated.map(async (e) => {
        await db.updated.delete(e.id)
      })
    )
    const createdData = await db.created.where('table').equals(table).toArray()
    await Promise.all(
      createdData.map(async (e) => {
        await parseItems(table, 'created', e, items, 'id')
      })
    )

    await addChangesToList(table, items)
  } catch (e) {
    throw new Error(e)
  }
}

const addChangesToList = async (table, items) => {
  const syncStore = useSyncStore()

  try {
    const changesList = syncStore.dataChangesList

    const newList = []

    await Promise.all(
      items.map(async (e) => {
        const index = changesList.findIndex((l) => {
          if (e.source_idb_id) {
            if (l.action === 'created') return l.item_id === e.source_idb_id
            else return false
          } else {
            return l.item_id === Number(e.source_id)
          }
        })

        if (index > -1) {
          if (changesList[index]?.action !== 'deleted') {
            if (!changesList[index][table]) {
              changesList[index][table] = []
            }

            changesList[index][table].push(e)
          }
        } else {
          let source

          if (e.source_idb_id) {
            source = await db.recons.where('id').equals(e.source_idb_id).first()
          } else if (e.source_id) {
            source = await db.recons.where('server_id').equals(Number(e.source_id)).first()
          } else {
            const { action, idbId } = e
            await db[action].delete(idbId)
            return
          }

          if (!source) {
            await db[e.action].delete(e.idbId)
            return
          }

          const { object_id, title } = source

          const objectData = object_id
            ? await db.objects.where('server_id').equals(object_id).first()
            : '-'
          const parseData = {
            id: `recons:updated:${e.source_id}`,
            object: objectData?.title_short || '-',
            item_id: e.recon_id,
            type: source?.data?.type || 1,
            idbId: source.id,
            [table]: [e],
            data: source?.data,
            action: 'updated',
            title,
            object_id
          }

          newList.push(parseData)
        }
      })
    )
    if (newList.length) {
      newList.forEach((e) => {
        const index = changesList.findIndex((l) => l.item_id === e.item_id)

        if (index > -1) {
          if (changesList[index]?.action !== 'deleted') {
            if (!changesList[index][table]) {
              changesList[index][table] = []
            }

            changesList[index][table].push(...e[table])
          }
        } else {
          changesList.push(e)
        }
      })
    }
  } catch (e) {
    throw new Error(e)
  }
}

const parseItems = async (table, action, item, items, keyField = 'server_id') => {
  if (item.excavation_id) return // Временное решение

  if (!item.item_id) {
    await db[action].delete(item.id)
  } else {
    const data = await db[table].where(keyField).equals(item.item_id).first()
    const source_id = action === 'deleted' ? item?.source_id : data?.source_id
    const parseData = {
      id: `${table}:${action}:${item.item_id}`,
      item_id: item.item_id,
      idbId: item.id,
      data: data?.data || data,
      source_idb_id: String(source_id).includes('idb_')
        ? Number(source_id.replace('idb_', ''))
        : null,
      action,
      source_id,
      source: data.table
    }

    if (action === 'deleted') {
      parseData.delete_url = item.delete_url
    }

    items.push(parseData)
  }
}

export const syncDataItem = async (source) => {
  const syncStore = useSyncStore()
  const mainStore = useMainStore()
  syncStore.setField('loading', true)

  try {
    switch (source.action) {
      case 'deleted':
        await deleteFromServer(source)
        break
      case 'updated':
        await updateOnServer(source)
        break
      case 'created':
        await createOnServer(source)
        break
    }
    if (syncStore.dataChangesList?.length) {
      syncStore.setField('initialDataList', cloneDeep(syncStore.dataChangesList))
    } else {
      mainStore.setNoSyncMode(false)
      setTimeout(() => {
        syncStore.setField('initialDataList', [])
      }, 1000)
    }
  } catch (e) {
    throw new Error(e)
  } finally {
    syncStore.setField('loading', false)
  }
}

export const syncAllData = async (list = []) => {
  const syncStore = useSyncStore()
  const mainStore = useMainStore()
  syncStore.setField('loading', true)

  try {
    const deletedSource = list.filter((e) => e.action === 'deleted')
    const updatedSource = list.filter((e) => e.action === 'updated')
    const createdSource = list.filter((e) => e.action === 'created')

    for (let i = 0; i < deletedSource.length; i++) {
      await deleteFromServer(deletedSource[i])
    }
    for (let i = 0; i < updatedSource.length; i++) {
      await updateOnServer(updatedSource[i])
    }
    for (let i = 0; i < createdSource.length; i++) {
      await createOnServer(createdSource[i])
    }
    mainStore.setNoSyncMode(false)
    setTimeout(() => {
      syncStore.setField('initialDataList', [])
    }, 1000)
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    syncStore.setField('loading', false)
  }
}

const deleteFromServer = async (item) => {
  const syncStore = useSyncStore()
  const { deleteRequest } = useRequests()

  try {
    const { id, idbId, item_id } = item
    await deleteRequest(`reconnaissance/${item_id}/`)
    await db.deleted.delete(idbId)
    syncStore.removeItemFromDataChangesList(id)
  } catch (e) {
    throw new Error(e)
  }
}

const updateOnServer = async (item) => {
  const syncStore = useSyncStore()
  const { patchRequest } = useRequests()

  try {
    const { id, idbId, item_id, data } = item
    const eniqueIds = {}

    delete data?.url
    delete data?.date
    if (item.isUpdated) {
      await patchRequest(`reconnaissance/${item_id}/`, data)
    }

    // SAVE IMAGES
    const images = item.images?.filter((v) => {
      if (!eniqueIds[v.id]) {
        eniqueIds[v.id] = true
        return true
      } else {
        return false
      }
    })

    const createdImages = images?.filter((s) => s.action === 'created')
    const deletedImages = images?.filter((s) => s.action === 'deleted')

    if (createdImages?.length) {
      await Promise.all(
        createdImages.map(async (e) => {
          await createImageOnServer(e)
        })
      )
    }
    if (deletedImages?.length) {
      await Promise.all(
        deletedImages.map(async (e) => {
          await deleteImageOnServer(e)
        })
      )
    }

    await db.updated.delete(idbId)
    syncStore.removeItemFromDataChangesList(id)
  } catch (e) {
    if (e.response && e.response?.status === 409) {
      e.response.data.table = 'images'
    }
    throw e
  }
}

const createOnServer = async (item) => {
  const syncStore = useSyncStore()
  const { postRequest } = useRequests()

  const { id, idbId, object_id, data, item_id } = item

  delete data.id

  let response
  try {
    response = await postRequest(`objects/${object_id}/reconnaissance/`, data)
  } catch (e) {
    if (e.response && e?.response?.status === 409) {
      e.response.data.table = 'recons'
      e.response.data.item_id = item_id
    }
    throw e
  }

  if (!response) return
  const recon = {
    ...response,
    id: item_id,
    server_id: response?.id,
    object_id
  }

  await db.recons.put(recon)
  await db.created.delete(idbId)

  try {
    if (item.images?.length && response?.id) {
      await Promise.all(
        item.images.map(async (e) => {
          await createImageOnServer(e)
        })
      )
    }
  } catch (e) {
    console.log(e, 'inner e')
    throw e
  }

  syncStore.removeItemFromDataChangesList(id)
}

const createImageOnServer = async (model) => {
  try {
    await saveImageOnServer(model.data)
    await db.images.delete(model.item_id)
    await db.created.delete(model.idbId)
  } catch (e) {
    console.log(e)
    throw e
  }
}

const deleteImageOnServer = async (item) => {
  const { deleteRequest } = useRequests()

  try {
    await deleteRequest(item.delete_url)
    await db.deleted.delete(item.idbId)
  } catch (e) {
    throw new Error(e)
  }
}

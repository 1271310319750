import { defineStore } from 'pinia'

export const useSyncStore = defineStore('sync', {
  state: () => {
    return {
      loading: false,
      changesList: [],
      initialChangesList: [],
      dataChangesList: [],
      initialDataList: []
    }
  },
  actions: {
    setField(field, value) {
      if (field.includes('.')) {
        const subField = field.split('.')

        this[subField[0]][subField[1]] = value
      } else {
        this[field] = value
      }
    },
    removeItemFromChangesList(id) {
      const index = this.changesList.findIndex((e) => e.id === id)

      if (index > -1) {
        this.changesList.splice(index, 1)
      }
    },
    removeItemFromDataChangesList(id) {
      const index = this.dataChangesList.findIndex((e) => e.id === id)

      if (index > -1) {
        this.dataChangesList.splice(index, 1)
      }
    }
  }
})

export const technoFields = {
  basic: [],
  additional: true,
  inclusions: true,
  interlayers: true
  // additional: [
  //   {
  //     id: 'inclusions',
  //     value: 'inclusions',
  //     title: 'Включения',
  //     type: 'inclusions'
  //   },
  //   {
  //     id: 'interlayers',
  //     value: 'interlayers',
  //     title: 'Прослои',
  //     type: 'interlayers'
  //   },
  //   {
  //     id: 'additional',
  //     value: 'additional',
  //     title: 'Укажите дополнительную информацию',
  //     type: 'additional',
  //     createTrigger: true
  //   }
  // ]
}

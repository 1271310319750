<script setup>
import { ref } from 'vue'
import SInputRenderer from '@/components/s-input-renderer/s-input-renderer.vue'

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  source: {
    type: Object,
    default: () => null
  },
  services: {
    type: Object,
    default: () => null
  },
  warning: {
    type: Boolean,
    default: false
  },
  required: {
    type: Array,
    default: () => []
  },
  activeId: {
    type: [Number, String],
    default: null
  }
})

const emits = defineEmits(['input', 'change'])

const initSource = ref(props.source)

const hasError = (value) => {
  return props.required?.includes(value)
}

const getColorType = () => {
  if (props.warning) return 'error'

  const { fields } = props.item
  if (!fields) return ''

  const length = fields.length || 0
  const filledLength = fields?.filter((e) => initSource.value[e.title])?.length || 0

  return filledLength === length ? 'success' : 'default'
}

const getHeaderCount = () => {
  const { fields } = props.item
  if (!fields) return ''

  const length = fields.length || 0
  const filledLength = fields?.filter((e) => initSource.value[e.title])?.length || 0

  return `${filledLength} / ${length}`
}

const changeActiveCollapse = (id) => {
  emits('change', id)
}
</script>

<template>
  <s-collapse-item
    @change="changeActiveCollapse"
    :active="activeId"
    :id="item.id"
    :title="item.title"
  >
    <template v-slot:header-stats>
      <s-icon
        v-if="warning"
        name="circle-exclamation"
        size="sm"
        class="header-stats__icon"
        color="var(--error)"
      />
      <s-text :type="getColorType()">
        {{ getHeaderCount() }}
      </s-text>
    </template>
    <s-input-renderer
      v-for="field in item.fields"
      :key="field.id"
      :field="field"
      :source="initSource"
      :services="services"
      :error="hasError(field.title)"
    />
  </s-collapse-item>
</template>

<style lang="scss">
.collapse-item {
  &__text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>

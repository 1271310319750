<script setup>
import { computed } from 'vue'
import { useServicesStore } from '@/stores'
import SInputRenderer from '@/components/s-input-renderer/s-input-renderer.vue'

const props = defineProps({
  source: {
    type: Object,
    required: true
  },
  field: {
    type: Object,
    required: true
  }
})

const servicesStore = useServicesStore()
const soilTypes = computed(() => {
  return servicesStore?.soil?.soil_types
})

const soilTypeLocal = computed(() => {
  return soilTypes.value?.find((e) => e.id === props.source?.soil_type)
})

const services = computed(() => {
  let soilId = soilTypeLocal.value?.id
  let soilClass = soilTypeLocal.value?.soil_class
  let soilGroup = soilTypeLocal.value?.soil_group
  let soilType = soilTypeLocal.value?.soil_type

  let service
  let fillerWaterSaturation

  if (props.field.service === 'filler_saturations') {
    const fillerType = servicesStore?.soil?.fillers_types?.find(
      (e) => e.id === props.source.filler_type
    )
    const apiName = fillerType?.api_dict
    fillerWaterSaturation = fillerType?.filler_water_saturation

    service = servicesStore?.soil?.[apiName]

    soilId = fillerType?.id
    soilClass = fillerType?.soil_class
    soilGroup = fillerType?.soil_group
    soilType = fillerType?.soil_type
  } else {
    service = servicesStore?.soil?.[props.field.service]
  }

  return {
    [props.field.service]: service?.filter((s) => {
      if (props.field.filterValue) {
        if (props.field.filterValue?.includes(';')) {
          const [from, to] = props.field.filterValue.split(';')

          return s.id >= Number(from) && s.id <= Number(to)
        } else {
          return props.field.filterValue?.includes(s.id)
        }
      }
      if (fillerWaterSaturation) {
        return s.filler_water_saturation === fillerWaterSaturation
      }

      const suits = s.soil_type
        ? s.soil_class === soilClass && s.soil_group === soilGroup && s.soil_type === soilType
        : s.soil_group
          ? s.soil_class === soilClass && s.soil_group === soilGroup
          : s.soil_class
            ? s.soil_class === soilClass
            : false

      if (s.soil_id?.includes(';')) {
        const [from, to] = s.soil_id.split(';')

        const between = soilId >= Number(from) && soilId <= Number(to)

        return (between || suits) && s.id
      } else {
        return (s.soil_id?.includes(String(soilId)) || suits) && s.id
      }
    })
  }
})
</script>

<template>
  <div class="soil-select">
    <s-input-renderer :field="field" :source="source" :services="services" />
  </div>
</template>

<style lang="scss" scoped>
.soil-select {
  display: grid;
  grid-gap: 1rem;
}
</style>

<script setup>
import { useAuthStore } from '@/stores'
import router from '@/router'

const authStore = useAuthStore()

const logout = () => {
  authStore.authLogout()
  router.push('/login')
}
</script>

<template>
  <section class="profile-loggout">
    <s-button stretch @click="logout"> Выйти из приложения </s-button>
  </section>
</template>

<style lang="scss"></style>

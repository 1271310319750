export const frozenRockFields = {
  basic: [
    {
      id: 'cement',
      title: 'cement',
      label: 'Укажите состав цемента грунта',
      type: 'select',
      service: 'cements',
      commentField: 'cement_comments'
    },
    {
      id: 'structure_texture',
      title: 'Укажите информацию о структуре',
      type: 'group',
      groups: [
        {
          id: 'struct_thaw_grains',
          title: 'struct_thaw_grains',
          label: 'Зерна / содержание частиц',
          type: 'select',
          service: 'structs_thaw_grains'
        },
        {
          id: 'text_thaw',
          title: 'text_thaw',
          label: 'Укажите текстуру грунта',
          type: 'select',
          service: 'texts_thaw'
        }
      ],
      commentField: 'struct_text_comments',
      commentTitle: 'Комментарий к структуре / текстуре'
    },
    {
      id: 'text_pfrost',
      title: 'text_pfrost',
      label: 'Укажите криогенную текстуру грунта',
      type: 'select',
      service: 'texts_pfrost',
      commentField: 'struct_text_comments',
      commentTitle: 'Комментарий к криогенной текстуре',
      wikiTipData: {
        color: 'placeholder',
        title: 'Криогенная текстура',
        description:
          'Совокупность признаков сложения мерзлого грунта, обусловленная ориентировкой, относительным расположением и распределением включений текстурообразующего льда и минеральных агрегатов',
        source:
          'ГОСТ Р 58325. Грунты. Полевое Описание, п. 3.4.5. АО «НИЦ «Строительство» — НИИОСП им. Н.М. Герсеванова», 2018'
      }
    },
    {
      id: 'alteration_rock1',
      title: 'Укажите информацию о степени измененности и прочности',
      type: 'group',
      groups: [
        {
          id: 'alteration_weath',
          title: 'alteration_weath',
          label: 'Степень выветрелости',
          type: 'select',
          service: 'alterations_weath'
        },
        {
          id: 'strength',
          title: 'strength',
          label: 'Прочность',
          type: 'select',
          service: 'strengths'
        },
        {
          id: 'density',
          title: 'density',
          label: 'Плотность',
          type: 'select',
          service: 'densities'
        }
      ],
      commentField: 'strength_comments',
      commentTitle: 'Комментарий к измененности и прочности'
    },
    {
      id: 'alteration_rock2',
      title: 'Укажите информацию о трещиноватости',
      type: 'group',
      groups: [
        {
          id: 'alteration_fractures_integrity',
          title: 'alteration_fractures_integrity',
          label: 'Степень сплошности',
          type: 'select',
          service: 'alterations_fractures_integrity'
        },
        {
          id: 'alteration_fractures_width',
          title: 'alteration_fractures_width',
          label: 'Ширина трещин',
          type: 'select',
          service: 'alterations_fractures_width'
        },
        {
          id: 'alteration_fractures_angles',
          title: 'alteration_fractures_angles',
          label: 'Ориентировка трещин',
          type: 'select',
          service: 'alterations_fractures_angles'
        },
        {
          id: 'alteration_fractures_compos',
          title: 'alteration_fractures_compos',
          label: 'Заполнитель трещин',
          type: 'select',
          service: 'alterations_fractures_compos'
        }
      ],
      commentField: 'alteration_fractures_comments',
      commentTitle: 'Комментарий к трещиноватости'
    },
    {
      id: 'composition_chem',
      title: 'composition_chem',
      label: 'Укажите карбонатность',
      type: 'select',
      service: 'compositions_chem'
    }
  ],
  additional: true,
  inclusions: true,
  interlayers: true
}

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useServicesStore } from '@/stores'
import SoilSelectItem from './common/soil-select-item.vue'
import SSearchBlock from '@/components/s-search-block/s-search-block.vue'

const props = defineProps({
  title: {
    type: String,
    default: null
  },
  source: {
    type: Object,
    required: true
  },
  soilInterval: {
    type: Object,
    required: true
  },
  field: {
    type: Object,
    required: true
  },
  noComment: {
    type: Boolean,
    default: false
  },
  filter: {
    type: [Object, Boolean],
    default: true
  }
})

const emits = defineEmits(['trigger-changed', 'change'])

const servicesStore = useServicesStore()

const filterText = ref('')
const initSource = ref(props.source)
const activeItem = ref(null)

const soilTypes = computed(() => {
  return servicesStore?.soil?.soil_types
})

const soilType = computed(() => {
  return soilTypes.value?.find((e) => e.id === props.source?.soil_type)
})

const soilEnd = computed(() => {
  if (soilType.value?.end2) return 2
  else if (soilType.value?.end3) return 3
  else return 1
})

const services = computed(() => {
  let soilId = soilType.value?.id
  let soilClass = soilType.value?.soil_class
  let soilGroup = soilType.value?.soil_group
  let soilTypeLocal = soilType.value?.soil_type

  let service
  let fillerWaterSaturation

  if (props.field.service === 'filler_saturations') {
    const fillerType = servicesStore?.soil?.fillers_types?.find(
      (e) => e.id === initSource.value.filler_type
    )
    const apiName = fillerType?.api_dict
    fillerWaterSaturation = fillerType?.filler_water_saturation

    service = servicesStore?.soil?.[apiName]

    soilId = fillerType?.id
    soilClass = fillerType?.soil_class
    soilGroup = fillerType?.soil_group
    soilTypeLocal = fillerType?.soil_type
  } else {
    service = servicesStore?.soil?.[props.field.service]
  }

  const filterTextLocal = filterText.value?.trim()?.toLowerCase()

  const filtered = !filterTextLocal
    ? service
    : service.filter((s) => {
        return (
          s.title?.trim()?.toLowerCase()?.includes(filterTextLocal) ||
          s.title_short?.trim()?.toLowerCase()?.includes(filterTextLocal)
        )
      })

  if (!props.filter) {
    return filtered.filter((s) => s.id)
  } else if (typeof props.filter === 'object' && props.filter !== null) {
    if (props.filter.attr === 'inclusion_type_null') {
      return filtered
    } else {
      return (
        filtered?.filter((s) => {
          return s[props.filter.attr] === props.filter.value
        }) || []
      )
    }
  } else {
    return (
      filtered?.filter((s) => {
        if (props.field.filterValue) {
          if (props.field.filterValue?.includes(';')) {
            const [from, to] = props.field.filterValue.split(';')

            return s.id >= Number(from) && s.id <= Number(to)
          } else {
            return props.field.filterValue?.includes(s.id)
          }
        }

        if (!s.soil_type && !s.soil_group && !s.soil_class && !s.soil_id) {
          return true
        }

        if (fillerWaterSaturation) {
          return s.filler_water_saturation === fillerWaterSaturation
        }

        const suits = s.soil_type
          ? s.soil_class === soilClass &&
            s.soil_group === soilGroup &&
            s.soil_type === soilTypeLocal
          : s.soil_group
            ? s.soil_class === soilClass && s.soil_group === soilGroup
            : s.soil_class
              ? s.soil_class === soilClass
              : false

        if (s.soil_id?.includes(';')) {
          const [from, to] = s.soil_id.split(';')

          const between = soilId >= Number(from) && soilId <= Number(to)

          return (between || suits) && s.id
        } else {
          return (s.soil_id?.includes(String(soilId)) || suits) && s.id
        }
      }) || []
    )
  }
})

const hasChanges = computed(() => {
  return !!initSource.value[props.field.id]
})

const setItem = (item) => {
  initSource.value[props.field.id] = item?.id && item?.id !== 0 ? item?.id : null

  activeItem.value = item
  emits('trigger-changed', hasChanges.value)
  emits('change', item?.id)
}

onMounted(() => {
  if (initSource.value[props.field.id]) {
    activeItem.value = services.value.find((e) => e.id === initSource.value[props.field.id])
    emits('trigger-changed', hasChanges.value)
  }
})
</script>

<template>
  <div class="create-guide-select-comp">
    <s-text v-if="title">
      {{ title }}
    </s-text>
    <s-search-block v-if="!activeItem" :filter="filterText" @change="filterText = $event" />
    <soil-select-item
      v-if="initSource[field.id] && activeItem"
      :item="activeItem"
      :end="soilEnd"
      active
      has-cancel
      @cancel="setItem(null)"
    />
    <div v-else :class="[`create-guide-select-comp__block ${field.layoutType || ''}`]">
      <soil-select-item
        v-for="item in services"
        :key="item.id"
        :item="item"
        :end="soilEnd"
        no-tip
        @handler="setItem(item)"
      />
    </div>
    <s-input
      v-if="!noComment && field.commentField"
      v-model="initSource[field.commentField]"
      type="textarea"
      :label="field.commentTitle || 'Комментарий'"
    />
  </div>
</template>

<style lang="scss">
.create-guide-select-comp {
  display: grid;
  grid-gap: 1rem;

  &__block {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;

    &.column {
      grid-template-columns: 1fr;
    }
  }

  > * {
    &.s-input {
      padding-top: 1rem;
    }
  }
}

@include phones {
  .create-guide-select-comp {
    &__block {
      grid-template-columns: 1fr 1fr;
    }
  }
}
</style>

import { defineStore } from 'pinia'
import {
  excavationStaticColumns,
  excavInitialColumns
} from '@/modules/app/data/objects/object/modules/sheets-page/modules/excavations/config'
import {
  reconsStaticColumns,
  initialReconColumns
} from '@/modules/app/data/objects/object/modules/sheets-page/modules/recons/config'

export const useSheetsStore = defineStore('sheets', {
  state: () => {
    return {
      isShowSelectedStats: false,
      selectedStats: {},
      staticStats: {},
      isLoadingSheetsData: false,
      excavStaticColumns: excavationStaticColumns,
      reconStaticColumns: reconsStaticColumns,
      showReconColumnsKeys: initialReconColumns,
      showColumnsKeys: excavInitialColumns,
      toolbarSearch: '',
      isShowSheetsColumnsModal: false,
      toolbarAction: null,
      coordinateFormat: null,
      excavsWithNewParams: null
    }
  },
  actions: {
    setField(field, value) {
      if (field.includes('.')) {
        const subField = field.split('.')

        this[subField[0]][subField[1]] = value
      } else {
        this[field] = value
      }
    },
    setStats(field, key, value) {
      this[field][key] = value
    },
    resetSelectedStats() {
      this.isShowSelectedStats = false
      this.selectedStats = {}
    }
  }
})

<script setup>
import { ref, onMounted, inject } from 'vue'
import { useAuthStore } from '@/stores'
import { useRequests } from '@/composables'

const emits = defineEmits(['toggle-loader'])

const authStore = useAuthStore()
const { patchRequest } = useRequests()
const $notify = inject('$notify')

const infoInputs = [
  { id: 'first_name', label: 'Имя' },
  { id: 'last_name', label: 'Фамилия' },
  { id: 'email', label: 'Электронная почта' }
]

const editingUser = ref(null)

const saveInfo = async () => {
  try {
    emits('toggle-loader', true)

    const url = 'users/profile/'
    const data = {
      ...editingUser.value
    }

    await patchRequest(url, data)
    await authStore.loadUserInfo()

    $notify({
      title: 'Сохранено',
      message: 'Профиль успешно сохранен',
      type: 'success'
    })
  } catch (error) {
    console.log(error)
  } finally {
    emits('toggle-loader', false)
  }
}

onMounted(() => {
  editingUser.value = {
    ...authStore.user
  }
})
</script>

<template>
  <div class="profile-editor__block">
    <div class="profile-editor__block-title">
      <s-title type="small"> Персональные данные </s-title>
    </div>

    <div class="profile-editor__inputs" v-if="editingUser">
      <div v-for="row in infoInputs" :key="row.id" class="profile-editor__row">
        <div class="profile-editor__input">
          <s-input v-model="editingUser[row.id]" :label="row.label" />
        </div>
      </div>
    </div>

    <div class="profile-editor__block-button">
      <s-button type="primary" @click="saveInfo"> Сохранить </s-button>
    </div>
  </div>
</template>

<style lang="scss"></style>

import db from '@/libs/db'
import { useRequests, useNotifyDeleteMessagesItem } from '@/composables'
import { useMainStore } from '@/stores'
import { getAllChanges } from '@/utils'
import appGlobalProperties from '@/main'

const deleteMessageItem = useNotifyDeleteMessagesItem()
export * from './images'
export * from './excavation'

const delete_urls = {
  groundwater: (id) => {
    return `groundwater/${id}/`
  },
  soils: (id) => {
    return `soils/${id}/`
  },
  coreboxes: (id) => {
    return `coreboxes/${id}/`
  },
  samples: (id) => {
    return `samples/${id}/`
  },
  boring: (id) => {
    return `borepass/${id}/`
  }
}

export const deleteObject = async (item, table = 'default', callback) => {
  if (!table) throw new Error('Не указана таблица')

  const { deleteRequest } = useRequests()
  const mainStore = useMainStore()

  const title = deleteMessageLib[table]?.title || deleteMessageLib.default?.title
  const message = deleteMessageLib[table]?.message || deleteMessageLib.default?.message

  appGlobalProperties
    .$confirm(message, title, {
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Отменить',
      confirmButtonClass: 'error'
    })
    .then(async () => {
      try {
        const url = delete_urls[table]?.(item.server_id)
        const excavIdb = item.excavation_id?.includes('idb')
        const excavId = excavIdb
          ? Number(item.excavation_id?.replace('idb_', ''))
          : Number(item.excavation_id)
        const excavation = await db.excavations
          .where({ [excavIdb ? 'id' : 'server_id']: excavId })
          .first()

        if (mainStore.isOnline && item?.server_id && !mainStore.noSyncMode) {
          await deleteRequest(url)
          await db.deleteObject(table, item.id)
        } else if (item?.server_id) {
          await db.deleteObject(table, item.id)
          await db.deleted.add({
            table,
            item_id: item.server_id,
            date: new Date(),
            delete_url: url,
            object_id: excavation.object_id,
            title: excavation.title,
            excavation_id: item.excavation_id
          })
          getAllChanges()
        } else if (!item?.server_id) {
          const createdItem = await db.created.where({ item_id: item.id }).first()
          await db.deleteObject(table, item.id)
          await db.created.delete(createdItem.id)

          getAllChanges()
        }

        if (callback) {
          callback()
        }

        const message = deleteMessageLib[table]?.success || deleteMessageLib.default?.success
        appGlobalProperties.$notify({
          title,
          message,
          type: 'success'
        })
      } catch (e) {
        const title = deleteMessageLib[table]?.errorTitle || deleteMessageLib.default?.errorTitle
        const message = deleteMessageLib[table]?.error || deleteMessageLib.default?.error
        appGlobalProperties.$notify({
          title,
          message,
          type: 'error'
        })
      }
    })
    .catch(() => {})
}

export const deleteHandler = async (item, type = 'default', callback, dataType = 'excavations') => {
  const mainStore = useMainStore()
  const { deleteRequest } = useRequests()

  const title = deleteMessageLib[type]?.title || deleteMessageLib.default?.title
  const message = deleteMessageLib[type]?.message || deleteMessageLib.default?.message

  appGlobalProperties
    .$confirm(message, title, {
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Отменить',
      confirmButtonClass: 'error'
    })
    .then(async () => {
      try {
        if (mainStore.isOnline && item?.server_id && !mainStore.noSyncMode) {
          const url = dataType === 'excavations' ? 'excavations' : 'reconnaissance'
          await deleteRequest(`${url}/${item.server_id}/`)
          await db.deleteObject(dataType, item.id)
        } else if (item?.server_id) {
          await db.deleteObject(dataType, item.id)
          await db.deleted.add({
            table: dataType,
            item_id: item?.server_id,
            date: new Date(),
            object_id: item.object_id,
            title: item.title
          })
          getAllChanges()
        } else if (!item?.server_id) {
          const existCreated = await db.created.get({ item_id: Number(item.id) })
          if (existCreated) {
            db.created.delete(existCreated.id)
          }
          await db.deleteObject(dataType, item.id)
          getAllChanges()
        }

        if (callback) {
          callback()
        }

        const message = deleteMessageLib[type]?.success || deleteMessageLib.default?.success
        appGlobalProperties.$notify({
          title,
          message,
          type: 'success'
        })
      } catch (e) {
        const title = deleteMessageLib[type]?.errorTitle || deleteMessageLib.default?.errorTitle
        const message = deleteMessageLib[type]?.error || deleteMessageLib.default?.error
        appGlobalProperties.$notify({
          title,
          message,
          type: 'error'
        })
      }
    })
    .catch(() => {})
}

export const deleteItem = async (url, type = 'default', callback) => {
  const messageItem = deleteMessageItem(type)

  const { title, message, success, errorTitle, error } = messageItem

  const { deleteRequest } = useRequests()

  appGlobalProperties
    .$confirm(message, title, {
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Отменить',
      confirmButtonClass: 'error'
    })
    .then(async () => {
      try {
        await deleteRequest(url)

        if (callback) {
          callback()
        }

        appGlobalProperties.$notify({
          title,
          message: success,
          type: 'success'
        })
      } catch (e) {
        appGlobalProperties.$notify({
          title: errorTitle,
          message: error,
          type: 'error'
        })
      }
    })
    .catch(() => {})
}

export const deleteMessageLib = {
  default: {
    title: 'Удаление',
    message: 'Вы действительно хотите удалить объект?',
    success: 'Объект успешно удален',
    errorTitle: 'Ошибка',
    error: 'Не удалось удалить объект'
  },
  objects: {
    title: 'Удаление проекта',
    message: 'Вы уверены, что хотите удалить данный проект? Это действие будет невозможно отменить',
    success: 'Проект успешно удален',
    errorTitle: 'Ошибка',
    error: 'Не удалось удалить проект'
  },
  excavations: {
    title: 'Удаление выработки',
    message:
      'Вы уверены, что хотите удалить данную выработку? Это действие будет невозможно отменить',
    success: 'Выработка успешно удалена',
    errorTitle: 'Ошибка',
    error: 'Не удалось удалить выработку'
  },
  recons: {
    title: 'Удаление точки наблюдения',
    message:
      'Вы уверены, что хотите удалить данную точку наблюдения? Это действие будет невозможно отменить',
    success: 'Точка наблюдения успешно удалена',
    errorTitle: 'Ошибка',
    error: 'Не удалось удалить точку наблюдения'
  },
  soils: {
    title: 'Удаление слоя',
    message:
      'Вы уверены, что хотите удалить выбранный слой? Это действие будет невозможно отменить',
    success: 'Слой успешно удален',
    errorTitle: 'Ошибка',
    error: 'Не удалось удалить слой'
  },
  users: {
    title: 'Удаление',
    message: 'Вы действительно хотите удалить пользователя?',
    success: 'Пользователь успешно удален',
    errorTitle: 'Ошибка',
    error: 'Не удалось удалить пользователя'
  }
}

<script setup>
import cloneDeep from 'lodash.clonedeep'
import { computed } from 'vue'
import { useExcavationStore } from '@/stores'
import { hasDataPermissions } from '@/utils'
import CoreboxesItem from './components/coreboxes-item.vue'

const props = defineProps({
  excavation: {
    type: Object,
    default: () => null
  }
})

const emits = defineEmits(['add-handler', 'edit-handler'])

const excavationStore = useExcavationStore()

const disabled = computed(() => {
  const geologist = props.excavation?.geologist

  return !hasDataPermissions(geologist)
})

const coreboxesList = computed(() => {
  const clone = cloneDeep(excavationStore.coreboxesList)

  return (
    clone?.sort((a, b) => {
      if (a.foot_d0 > b.foot_d0) return 1
      if (a.foot_d0 < b.foot_d0) return -1
      return 0
    }) || []
  )
})

const clickHandler = (corebox) => {
  if (disabled.value) return
  emits('edit-handler', { model: 'coreboxes', value: corebox })
}
</script>

<template>
  <div class="coreboxes-list">
    <div v-if="!disabled" class="coreboxes-list__btn-wrapper">
      <s-button type="success-plain" icon="fa-plus" @click="emits('add-handler', 'coreboxes')">
        Добавить фото керна
      </s-button>
    </div>
    <div v-if="coreboxesList.length" class="coreboxes-list-content">
      <coreboxes-item
        v-for="(corebox, i) in coreboxesList"
        :key="corebox.id"
        :no="i + 1"
        :corebox="corebox"
        @click="clickHandler(corebox)"
      />
    </div>
  </div>
</template>

<style lang="scss">
.coreboxes-list {
  display: grid;
  grid-gap: 1rem;

  &__btn-wrapper {
    position: sticky;
    top: 0;
    z-index: 10;
    background: var(--card-bg);

    & .s-button {
      width: 100%;
    }
  }

  &-content {
    display: grid;
    grid-gap: 1rem;
  }
}
</style>

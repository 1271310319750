<script setup>
import { ref, computed, watch, inject, onMounted, onUnmounted } from 'vue'
import { useMq } from 'vue3-mq'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'

import { useMainStore, useObjectsStore, useExcavationStore, useMapStore } from '@/stores'
import { DataController } from './controllers/data.js'
import { editExcavation, reloadWindow } from '@/utils'
import { basicFields, secondaryFields } from './config'

import unassignedGeologistsTip from '../objects/object/components/unassigned-geologists-tip.vue'
import CardLayout from '@/layouts/card-layout.vue'
import DataCard from '@/components/data-card.vue'
import DataStatusSwitcher from '@/components/data-status-switcher.vue'
import ExcavationSoils from './components/excavation-soils/excavation-soils.vue'
import DataMap from '@/modules/app/data/maps/data-map/data-map.vue'
import DataDesktopLayout from '@/layouts/desktop/data-desktop-layout.vue'
import ExcavationEditor from './components/excavation-editor/excavation-editor.vue'

const mq = useMq()
const route = useRoute()
const router = useRouter()
const $notify = inject('$notify')

const mainStore = useMainStore()
const objectsStore = useObjectsStore()
const excavationStore = useExcavationStore()
const mapStore = useMapStore()

onBeforeRouteLeave((_, from, next) => {
  mainStore.setLastRoutePath(['SET_LAST_OBJECT_PATH', from.fullPath])
  next()
})

const ready = ref(false)
const excavationUpdating = ref(false)
const dataController = ref(null)

const excavation = computed(() => {
  return excavationStore.active
})
const excavationTitle = computed(() => {
  return excavation.value?.title || '-'
})

const excavationId = computed(() => {
  return route.query.id
})

const objectId = computed(() => {
  return excavation.value?.object_id
})

const isIdb = computed(() => {
  return String(excavationId.value).includes('idb_')
})

const parsedId = computed(() => {
  const id = !isIdb.value ? excavationId.value : excavationId.value.replace('idb_', '')
  return id ? Number(id) : null
})

watch(
  () => objectsStore.excavationUpdated,
  (val) => {
    if (!val) return
    dataController.value.updateHandler('excavations')
    objectsStore.setField('excavationUpdated', false)
  }
)

watch(
  () => excavationStore.updateList,
  (val) => {
    if (!val) return
    dataController.value.updateHandler('soils')
    excavationStore.setField('updateList', false)
  }
)

watch(
  () => excavationStore.updateSamples,
  (val) => {
    if (!val) return
    dataController.value.updateHandler('samples')
    excavationStore.setField('updateSamples', false)
  }
)

watch(
  () => excavationStore.updateGroundwater,
  (val) => {
    if (!val) return
    dataController.value.updateHandler('groundwater')
    excavationStore.setField('updateGroundwater', false)
  }
)

watch(
  () => excavationStore.updateCoreboxes,
  (val) => {
    if (!val) return
    dataController.value.updateHandler('coreboxes')
    excavationStore.setField('updateCoreboxes', false)
  }
)

watch(
  () => excavationStore.updateExcavationImages,
  async (newValue) => {
    if (newValue) {
      dataController.value.updateHandler('images')
      excavationStore.setField('updateExcavationImages', false)
    }
  }
)

watch(
  () => parsedId.value,
  async () => {
    excavationUpdating.value = true
    await dataController.value.loadAllAssets('update')
    excavationUpdating.value = false
  }
)

const init = async () => {
  try {
    mainStore.setLoading(true)

    await dataController.value.loadAllAssets()
    ready.value = true
  } catch (e) {
    console.log(e)
    $notify({
      message: `Произошла ошибка при загрузке скважины. ${e}`,
      type: 'error'
    })
  } finally {
    mainStore.setLoading(false)
  }
}

const flyTo = () => {
  mapStore.setCenterMapOnActive(true)
}

const goBack = () => {
  if (objectId.value) {
    router.push(`/app/data/objects/${objectId.value}`)
  } else {
    router.push('/app/data/objects/list')
  }
}

onMounted(() => {
  if (!parsedId.value) {
    const title = 'Ошибка'
    const message = `Некорректный идентификатор проекта. Обратитесь к администратору.`

    $notify({
      title,
      message,
      type: 'error'
    })

    router.push('/app/not-found')
  } else {
    dataController.value = new DataController()
    init()
  }
})

onUnmounted(() => {
  excavationStore.setField('active', null)
  excavationStore.setField('soilsList', null)
  excavationStore.setField('excavationImages', [])
})

const updateHandler = async () => {
  dataController.value.updateHandler()
}

const panelWidth = computed(() => {
  return mq.current === 'lg' ? '720px' : '100%'
})

const isShowEditModal = ref(false)

const editModal = () => {
  isShowEditModal.value = !isShowEditModal.value
}

const statusHandler = async (e) => {
  const data = excavation.value
  data.status = e
  const filter = { field: 'object_id', value: excavation.value.object_id }

  await editExcavation(data, false, false, filter)

  updateHandler()
}
</script>

<template>
  <data-desktop-layout :left-panel-width="panelWidth">
    <card-layout
      v-loading="excavationUpdating"
      v-if="excavation"
      :title="excavationTitle"
      back-button
      @back="goBack()"
    >
      <template #header-actions>
        <div v-if="mq.current === 'lg'" v-tooltip="'Показать на карте'" class="action__crosshair">
          <s-button icon="location-crosshairs" @click="flyTo" />
        </div>
        <data-status-switcher :item="excavation" @change="statusHandler" />
        <div v-tooltip="'Обновить'">
          <s-button icon="arrows-rotate" @click="reloadWindow" />
        </div>
      </template>
      <unassigned-geologists-tip :excavationTitle="excavationTitle" v-if="mq.current === 'lg'" />
      <data-card
        v-if="dataController"
        :item="excavation"
        :object="dataController.object"
        :basic-fields="basicFields"
        :secondary-fields="secondaryFields"
        @edit-modal="editModal"
      />
      <excavation-soils
        v-if="ready"
        :excavation="excavation"
        :object="dataController.object"
        @update-handler="updateHandler"
      />
    </card-layout>
    <template #map>
      <data-map v-if="mq.current === 'lg' && !!objectsStore.excavationsList" type="data-points" />
    </template>

    <excavation-editor
      v-if="isShowEditModal"
      title="Редактирование"
      :is-visible="isShowEditModal"
      :excavation="excavation"
      @toggle="isShowEditModal = !isShowEditModal"
      @update-handler="updateHandler"
    />
  </data-desktop-layout>
</template>

<style lang="scss">
.excavation {
  display: grid;
  height: 100%;
  grid-gap: 1.5rem;
  align-content: start;
}

.action__crosshair {
  & svg {
    font-size: 14px;
  }
}
</style>

<script setup>
import { ref, onMounted } from 'vue'
import { useMainStore } from '@/stores'
import { getDataFromLS, saveDataToLS } from '@/utils'

import MobileBar from './components/mobile-bar.vue'
import PwaInstallTip from '@/components/pwa-install-tip.vue'

const mainStore = useMainStore()

const isInstallTipShow = ref(false)

const showInstallTip = () => {
  const addedToHS = matchMedia('(display-mode: standalone)').matches
  if (addedToHS) return

  const flag = getDataFromLS('isInstallTipShowed')
  if (flag) return

  isInstallTipShow.value = true
  saveDataToLS('isInstallTipShowed', true)
}

onMounted(() => {
  setTimeout(() => {
    showInstallTip()
  }, 500)
})
</script>

<template>
  <div class="app" v-loading="mainStore.loading">
    <router-view class="app-content" />
    <mobile-bar />
    <pwa-install-tip v-if="isInstallTipShow" @close-install-tip="isInstallTipShow = false" />
  </div>
</template>

<style lang="scss">
.app {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.md,
.sm {
  .app {
    display: flex;
    flex-direction: column;

    &-content {
      flex: 1;
      min-height: 0;
    }
  }
}
</style>

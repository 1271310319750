<script setup>
import { computed, onMounted, ref, reactive, inject } from 'vue'
import { useMq } from 'vue3-mq'

import { useAuthStore, useObjectsStore, useMainStore } from '@/stores'
import { useRequests } from '@/composables'
import { checkStorage, updateStorage } from '@/libs/db/helpers'
import db from '@/libs/db'
import { filterData, sortData } from '@/utils'
import { filtersConfig } from './config'

import DataDesktopLayout from '@/layouts/desktop/data-desktop-layout.vue'
import DataMobileLayout from '@/layouts/mobile/data-mobile-layout.vue'
import SSearchBlock from '@/components/s-search-block/s-search-block.vue'
import ListComp from './components/object-list.vue'
import ObjectCreate from '../object-editor/object-create.vue'
import DataMap from '../../maps/data-map/data-map.vue'

const { getRequest } = useRequests()
const mq = useMq()
const $notify = inject('$notify')

const comps = [
  { id: 'list', name: 'Список' },
  { id: 'map', name: 'Карта' }
]

const objectsStore = useObjectsStore()
const authStore = useAuthStore()
const mainStore = useMainStore()

const isAdmin = computed(() => {
  return authStore.isAdmin
})

const filtersLocal = reactive(filtersConfig)
const filterText = ref('')
const activeComp = ref('list')
const modalVisible = ref(false)

const toggleModal = () => {
  modalVisible.value = !modalVisible.value
}

const objects = computed(() => {
  return objectsStore.objectsList || []
})

const filteredObjects = computed(() => {
  const filterTextValue = filterText.value?.trim()?.toLowerCase()
  const { filters, sort } = filtersLocal

  const filteredList = filterData(objects.value, filters)
  const sortedList = sortData(filteredList, filterTextValue, sort, [
    'title',
    'title_short',
    'client_title',
    'contractor_title',
    'num'
  ])

  return sortedList
})

const changeTab = (value) => {
  activeComp.value = value
}

onMounted(() => {
  getObjects()
})
const getObjects = async () => {
  mainStore.setLoading(true)

  try {
    await db.updateStoreData('objects')

    if (mainStore.isOnline) {
      const { results: server } = await getRequest('objects/')
      await checkStorage('objects', objects.value, server)
      await updateStorage('objects', objects.value, server)
    }
  } catch (e) {
    console.log(e)
    $notify({
      message: `Произошла ошибка при загрузке списка проектов. ${e}`,
      type: 'error'
    })
  } finally {
    mainStore.setLoading(false)
  }
}
</script>

<template>
  <data-desktop-layout v-if="mq.current === 'lg'" left-panel-width="480px">
    <div class="objects-list-page">
      <s-search-block
        :has-button="isAdmin"
        :filter="filterText"
        placeholder="Поиск по проектам"
        tooltip-text="Создать проект"
        :filters="filtersLocal"
        @change="(e) => (filterText = e)"
        @click-handler="toggleModal"
      />
      <list-comp
        v-if="activeComp === 'list'"
        :objects="filteredObjects"
        :filtered="!!filterText?.trim()"
      />
    </div>
    <template #map>
      <data-map />
    </template>

    <object-create v-if="modalVisible" :is-visible="modalVisible" @toggle="toggleModal" />
  </data-desktop-layout>

  <data-mobile-layout v-else>
    <div class="objects-list-page">
      <s-search-block
        :has-button="isAdmin"
        :filter="filterText"
        :filters="filtersLocal"
        pop-tip-width="240"
        placeholder="Поиск по проектам"
        @change="(e) => (filterText = e)"
        @click-handler="toggleModal"
      />
      <s-div col space-between>
        <s-title>Проекты</s-title>
        <s-tabs @click="changeTab" :value="activeComp" :tabs="comps" indent small>
          <s-tab
            :active="activeComp"
            v-for="comp in comps"
            :id="comp.id"
            :key="comp.id"
            :name="comp.name"
          />
        </s-tabs>
      </s-div>
      <list-comp
        v-if="activeComp === 'list'"
        :objects="filteredObjects"
        :filtered="!!filterText?.trim()"
      />
      <div v-else-if="activeComp === 'map'" class="objects-map-wrapper">
        <data-map />
      </div>
    </div>
    <object-create v-if="modalVisible" :is-visible="modalVisible" @toggle="toggleModal" />
  </data-mobile-layout>
</template>

<style lang="scss">
.objects-list-page {
  position: relative;
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
  width: 100%;
  align-content: flex-start;

  & .objects-map-wrapper {
    position: relative;
    display: flex;
    height: 100%;
    border-radius: var(--border-radius);
    border: 1px solid var(--main-bg);
    overflow: hidden;
  }
}

.md,
.sm {
  & .objects-list-page {
    padding: 1rem;
  }
}
</style>

<script setup>
import { ref, computed, inject } from 'vue'
import { useRequests } from '@/composables'
import { useAuthStore } from '@/stores'

const emits = defineEmits(['toggle-loader'])

const { postRequest } = useRequests()
const authStore = useAuthStore()
const $notify = inject('$notify')

const newPassword = ref('')
const checkedPassword = ref('')

const isPasswordsEqual = computed(() => {
  return newPassword.value && checkedPassword.value === newPassword.value
})

const changePassword = async () => {
  try {
    emits('toggle-loader', true)

    const url = 'users/profile/password/'
    const data = {
      password: newPassword.value,
      password1: checkedPassword.value
    }

    await postRequest(url, data)

    const userInfo = {
      username: authStore.user?.username,
      password: newPassword.value
    }

    await authStore.authLogout()
    await authStore.authRequest(userInfo)

    newPassword.value = ''
    checkedPassword.value = ''

    $notify({
      title: 'Сохранено',
      message: 'Пароль успешно сохранен',
      type: 'success'
    })
  } catch (error) {
    console.log(error)

    const data = error?.response?.data

    if (data && Object.values(data).length) {
      $notify({
        title: 'Ошибка сохранения',
        message: Object.values(data).flat().join(' '),
        type: 'error'
      })
    }
  } finally {
    emits('toggle-loader', false)
  }
}
</script>

<template>
  <div class="profile-editor__block">
    <div class="profile-editor__block-title">
      <s-title type="small"> Изменение пароля </s-title>
    </div>
    <div class="profile-editor__inputs">
      <div class="profile-editor__row">
        <div class="profile-editor__input">
          <s-input v-model="newPassword" type="password" label="Пароль" />
        </div>
        <div class="profile-editor__input">
          <s-input v-model="checkedPassword" type="password" label="Подтвердите пароль" />
        </div>
      </div>
    </div>

    <div class="profile-editor__block-button">
      <s-button type="primary" :disabled="!isPasswordsEqual" @click="changePassword">
        Изменить пароль
      </s-button>
    </div>
  </div>
</template>

<style lang="scss"></style>

<script setup>
import { nextTick, onMounted, ref, watch } from 'vue'
import 'swiper/swiper-bundle.css'
import { register } from 'swiper/element/bundle'
import { Thumbs, Zoom, Keyboard, Mousewheel } from 'swiper/modules'
register({ Thumbs, Zoom, Keyboard, Mousewheel })

import 'swiper/element/css/thumbs'
import 'swiper/element/css/zoom'

const props = defineProps({
  images: {
    type: Array,
    default: () => []
  },
  spaceBetween: {
    type: Number,
    default: 10
  },
  slidesPerView: {
    type: Number,
    default: 1
  },
  centeredSlides: {
    type: Boolean,
    default: true
  },
  navigation: {
    type: Boolean,
    default: false
  },
  thumbnails: {
    type: Boolean,
    default: false
  },
  thumbnailsSlidesPerView: {
    type: [Number, String],
    default: 'auto'
  },
  thumbnailsWidth: {
    type: [Number, String],
    default: 'auto'
  },
  zoom: {
    type: Boolean,
    default: true
  },
  initialSlide: {
    type: Number,
    default: 0
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const thumbs = ref(null)
const mainSwiper = ref(null)

const initThumbs = () => {
  const swiperParams = {
    touchRatio: 0.2,
    spaceBetween: props.spaceBetween,
    breakpoints: {
      320: {
        slidesPerView: 4
      },
      480: {
        slidesPerView: 5
      },
      640: {
        slidesPerView: 8
      }
    },
    slideToClickedSlide: true
  }

  Object.assign(thumbs.value, swiperParams)

  thumbs.value.initialize()
}

const downloadCurrentImage = () => {
  const swiper = mainSwiper.value.swiper
  const activeIndex = swiper.activeIndex
  const link = document.createElement('a')
  link.href = props.images[activeIndex]
  link.download = `Изображение ${activeIndex + 1}.jpg`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

watch(
  () => props.images,
  () => {
    if (props.thumbnails) {
      nextTick(() => {
        initThumbs()
      })
    }
  }
)

onMounted(() => {
  if (props.thumbnails) {
    initThumbs()
  }
})
</script>

<template>
  <div class="s-swiper" v-loading="loading">
    <swiper-container
      ref="mainSwiper"
      :zoom="zoom"
      :keyboard="true"
      :mousewheel="true"
      :slides-per-view="slidesPerView"
      :centeredSlides="centeredSlides"
      thumbs-swiper=".s-swiper-thumbs"
      :navigation="navigation"
      :spaceBetween="spaceBetween"
      class="s-swiper-container"
      :injectStyles="[
        `
        :host .swiper-button-prev,
          .swiper-button-next {
            color: var(--caption);
          }
      `
      ]"
      :initialSlide="initialSlide"
    >
      <swiper-slide v-for="(image, index) in images" :key="image">
        <div class="swiper-zoom-container" :data-index="index">
          <img :src="image" alt="Image" />
        </div>
      </swiper-slide>
    </swiper-container>

    <div class="controls">
      <s-button @click="downloadCurrentImage" :icon="'download'" simple />
    </div>

    <slot />

    <swiper-container ref="thumbs" :init="false" class="s-swiper-thumbs">
      <swiper-slide class="swiper-slide" v-for="(image, index) in images" :key="`thumb-${index}`">
        <img :src="image" alt="Thumbnail" />
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<style lang="scss">
.s-swiper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 16px;
  max-width: 100%;
  height: 100%;
  position: relative;
  align-items: center;

  &-container {
    overflow: hidden;
    max-width: 100%;
    border-radius: 8px;
    flex-grow: 1;
    height: 100%;
  }

  &-thumbs {
    overflow: hidden;
    max-width: 100%;
    height: 15%;

    & swiper-slide {
      min-height: 64px;
      min-width: 64px;
    }

    & .swiper-slide-thumb-active {
      & img {
        border: 1px solid var(--primary);
      }
    }

    & img {
      width: 64px;
      aspect-ratio: 1 / 1;
    }
  }

  & swiper-slide {
    max-width: 100%;
    max-height: 100%;

    & img {
      border-radius: 8px;
      border: 1px solid var(--main-bg);
      overflow: hidden;
      max-width: 100%;
      max-height: 100%;
      height: auto;
      width: auto;
      min-height: 64px;
      min-width: 64px;
      object-fit: cover;
      transition: transform 300ms;
    }
  }
}

.controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
}
</style>

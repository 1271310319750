<script setup>
import SSearchBlockFilters from './s-search-block-filters.vue'
import debounce from 'lodash.debounce'
import { useMq } from 'vue3-mq'
import { computed, ref, watch } from 'vue'

const props = defineProps({
  filter: {
    type: [String, null],
    required: true
  },
  placeholder: {
    type: String,
    default: 'Поиск'
  },
  tooltipText: {
    type: String,
    default: null
  },
  hasButton: {
    type: Boolean,
    default: false
  },
  filters: {
    type: Object,
    default: () => null
  },
  popTipWidth: {
    type: String,
    default: null
  },
  dataPage: {
    type: Boolean,
    default: false
  },
  ignoreActiveFilters: {
    type: Array,
    default: null
  }
})

const emits = defineEmits(['change', 'click-handler', 'after-select', 'change-field'])

const mq = useMq()

const value = ref(props.filter)
const filtersVisible = ref(false)

const active = computed(() => {
  return filteredFilters.value.filters.some((item) => {
    return item.filterValues?.some((e) => !e.value)
  })
})

watch(
  () => value.value,
  (newVal) => {
    updateInput(newVal)
  },
  { deep: true }
)

const updateInput = debounce(function (val) {
  emits('change', val)
}, 150)

const afterSelect = (selectValue) => {
  emits('after-select', selectValue)
  filtersVisible.value = false
}

const emitsFieldsChanged = (field, id, value) => {
  emits('change-field', field, id, value)
}

const dropdownItems = ref([
  {
    id: 'excavation',
    label: 'Создать скважину'
  },
  {
    id: 'recon',
    label: 'Создать точку наблюдения'
  }
])

const dropdownClickHandle = (id) => {
  switch (id) {
    case 'excavation':
      emits('click-handler', id)
      break
    case 'recon':
      emits('click-handler', id)
  }
}

const filteredFilters = computed(() => {
  const isDesktopScreen = mq.current === 'lg'

  if (!props.filters) return

  return {
    ...props.filters,
    filters: props.filters.filters.filter((filter) => {
      return !isDesktopScreen || !filter.showOnlyOnMobile
    })
  }
})
</script>

<template>
  <div class="s-search-block">
    <div :class="['s-search-block__input-block', { filters: filteredFilters }]">
      <s-input
        v-model="value"
        clearable
        :placeholder="placeholder"
        :icon-name="'magnifying-glass'"
      />
      <s-pop-tip
        v-if="filteredFilters"
        v-model="filtersVisible"
        :width="popTipWidth"
        trigger="click"
      >
        <div v-tooltip.left="'Фильтры и сортировка'">
          <s-button :class="[{ active }]" class="s-search-block__filter-button" icon="filter" />
        </div>
        <template #content>
          <s-search-block-filters
            @change-field="emitsFieldsChanged"
            @after-select="afterSelect"
            :filters="filteredFilters"
          />
        </template>
      </s-pop-tip>
    </div>
    <div v-if="hasButton" v-tooltip.left="tooltipText">
      <s-dropdown v-if="dataPage" list stick-to="right" :max-width="256">
        <s-button type="success" icon="plus" />
        <template #dropdown>
          <s-dropdown-item
            v-for="item in dropdownItems"
            :id="item.id"
            :key="item.id"
            :type="item.type"
            :icon="item.icon"
            :label="item.label"
            :divided="item.divided"
            :disabled="item.disabled"
            :close-by-click="item.closeByClick"
            :centerItem="false"
            @click="dropdownClickHandle"
          />
        </template>
      </s-dropdown>
      <s-button v-else type="success" icon="plus" @click="emits('click-handler')" />
    </div>
  </div>
</template>

<style lang="scss">
.s-search-block {
  display: flex;
  gap: 16px;

  & svg {
    font-size: 14px;
  }

  &__filter-button {
    border: 1px solid var(--main-bg) !important;
  }

  & .active::before {
    z-index: 1;
    top: 12%;
    right: 12%;
    border-radius: 50%;
    content: '';
    width: 11px;
    height: 11px;
    background: var(--primary);
    border: 1px solid var(--card-bg);
    position: absolute;
  }

  &__input-block {
    display: flex;
    width: 100%;

    .s-input {
      width: 100%;
    }

    &.filters {
      .s-input__input {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .s-button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
      }
    }
  }
}
</style>

<script setup>
import SSwiper from '@/components/s-swiper/s-swiper.vue'
import SampleType from '@/modules/app/data/excavation/components/excavation-samples/components/sample-type.vue'

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false
  },
  thumbnails: {
    type: Boolean,
    default: false
  },
  images: {
    type: Array,
    default: null
  },
  thumbnailsSlidesPerView: {
    type: [Number, String],
    default: 'auto'
  },
  source: {
    type: Object,
    default: () => null
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['close'])

const getTitle = () => {
  const from = props.source?.selection_from_d?.toFixed(1)
  const to = props.source?.selection_to_d?.toFixed(1)
  return `Проба ${from} - ${to} м`
}
</script>

<template>
  <s-modal title="Изображения" :show="isVisible" fullscreen @close="emits('close')">
    <s-swiper
      :thumbnailsSlidesPerView="thumbnailsSlidesPerView"
      :thumbnails="thumbnails"
      navigation
      :loading="loading"
      :images="images"
    >
      <div v-if="source" class="attachments-gallery-modal__info">
        <s-text>
          {{ getTitle() }}
        </s-text>
        <sample-type :sample="source" />
      </div>
    </s-swiper>
  </s-modal>
</template>
